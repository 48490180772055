import React  from 'react';
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import clsx from 'clsx';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import * as actions from '../../../Store/actions/index'
import { amber, green } from '@material-ui/core/colors';
// import CheckCircle from '@material-ui/icons/CheckCircle';
// import Error from '@material-ui/icons/Error';
// import Info from '@material-ui/icons/Info';
// import Warning from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.main,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
      },
  }));

//   const typeIcon = {
//     success: CheckCircle,
//     warning: Warning,
//     error: Error,
//     info: Info,
//   };


export default function SuccessSnackbar() {
  const classes = useStyles();  
  const dispatch = useDispatch();

  const snackbarMessage = useSelector(state => state.ui.snackbarMessage);  
  const snackbarOpen = useSelector(state => state.ui.snackbarOpen);
  const snackbarType = useSelector(state => state.ui.snackbarType);

  function handleClose() {
    dispatch(actions.clearSnackbar());
  }

  return (
    <Snackbar
     className={classes[snackbarType]}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={snackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    //   aria-describedby="client-snackbar"
    >
    <SnackbarContent
     className={clsx(classes[snackbarType])}
      message={
        <span className={classes.message } id="client-snackbar">
             <Icon className={classes.icon } />
          {snackbarMessage}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <Icon>close</Icon>
        </IconButton>
      ]}
      />
       </Snackbar>

  );
}

