import * as actionTypes from '../actions/actionTypes';
import axios from 'axios';



export const fetchAcctSegStart = () => {
    return {
        type: actionTypes.FETCH_ACCTSEG_START
    };
};


export const fetchAcctSegSuccess = (segments) => {
        return {
            type: actionTypes.FETCH_ACCTSEG_SUCCESS,
            segments: segments    
        };         
    };

export const  fetchAcctSegFail = (error) => {
    return {
        type: actionTypes.FETCH_ACCTSEG_FAIL,
        error: error
        
    };
};



export const fetchSegLookStart = () => {
    return {
        type: actionTypes.FETCH_SEGLOOK_START
    };
};


export const fetchSegLookSuccess = (seglook) => {
        return {
            type: actionTypes.FETCH_SEGLOOK_SUCCESS,
            seglook: seglook   
        };         
    };

export const  fetchSegLookFail = (error) => {
    return {
        type: actionTypes.FETCH_SEGLOOK_FAIL,
        error: error
        
    };
};


export const fetchAcctSeg = (token) => {

    return dispatch => {

         dispatch(fetchAcctSegStart());
         const queryParms = '?auth=' + token           
         console.log(queryParms)
             
              axios.get('https://checkrequest-20a0f.firebaseio.com/Coasetup/-LpZsV3J4VXnirgJZsqs/WAKEFERN/ERP1/segments.json' + queryParms  )
         .then(res => {
            const fetchedResults =[];
            for (let key in res.data) {
                    fetchedResults.push({
                          ...res.data[key],
                          id:key
                      });
              }
              console.log(fetchedResults);
             dispatch(fetchAcctSegSuccess(fetchedResults));
            
         })
         .catch(err => {
             dispatch(fetchAcctSegFail());
         });
     
     
          
     };
 };


 export const fetchSegLook = (token) => {

    return dispatch => {
     
         dispatch(fetchSegLookStart());
         const queryParms = '?auth=' + token           
         console.log(queryParms)             
              axios.get('https://checkrequest-20a0f.firebaseio.com//Segments/-Lp_PDm4u_9enmHz5yvC/WAKEFERN/ERP1/segments.json/' + queryParms  )
         .then(res => {
            const fetchedResults =[];
            for (let key in res.data) {
                    fetchedResults.push({
                          ...res.data[key],
                          id:key
                      });
              }
              console.log(fetchedResults);
             dispatch(fetchSegLookSuccess(fetchedResults));
            
         })
         .catch(err => {
             dispatch(fetchSegLookFail());
         });
     
     
          
     };
 }; 