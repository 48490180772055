import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility'



const initialState = {
    firstName: null,
    lastName: null,
    role: null,
    approver: null,
    error: null,
    loading: false,
   }


const userStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
}

const userSuccess = (state, action) => { 
    return updateObject(state, {
        firstName: action.firstName,
        lastName: action.lastName,
        role: action.role,   
        approver: action.approver     
    });

}


const userFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}



const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.USER_START: return userStart(state, action);
        case actionTypes.USER_SUCCESS: return userSuccess(state, action);
        case actionTypes.USER_FAIL: return userFail(state, action);
               
        default:
            return state;
    }


};

export default reducer;