import React from "react";
import { withStyles } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
//import { NavDrawer } from "./NavDrawer";
import Logo from '../../../Component/Logo/Logo'
import { withRouter } from "react-router-dom";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(4),

  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },

});


class NavBar extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     drawerOpened: false
  //   };
  // }
  // toggleDrawer = booleanValue => () => {
  //   this.setState({
  //     drawerOpened: booleanValue
  //   });
  // };



  handleCallToRouter = (e, value) => {
    e.preventDefault();
    console.log(this.props.history);
    this.props.history.push(value)
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">

          <Toolbar>


            <Logo />
            <Typography variant="h6" color="inherit" className={classes.title} onClick={e => this.handleCallToRouter(e, "/")} >
              Check Request
            </Typography>

            <Button color="inherit" onClick={e => this.handleCallToRouter(e, "/")} >Home</Button>
            {this.props.isAuth ?
              <Button color="inherit" onClick={e => this.handleCallToRouter(e, "/vendor")} >New Request</Button>
              : null
            }
            {this.props.isAuth ?
              <Button color="inherit" onClick={e => this.handleCallToRouter(e, "/requests")} >Review Requests</Button>
              : null
            }
            {!this.props.isAuth ?
              <Button color="inherit" onClick={e => this.handleCallToRouter(e, "/Login")} >Login</Button>
              :
              (
                <>
                  <Button color="inherit" onClick={e => this.handleCallToRouter(e, "/Logout")} >Logout</Button>
                  <IconButton color="inherit" aria-label="Notification" component="span">
                    <Badge badgeContent={4} color="secondary">
                      <NotificationsIcon fontSize="large" />
                    </Badge>
                  </IconButton>

                  <IconButton color="inherit" aria-label="Profile" component="span">
                    <AccountCircleIcon fontSize="large"  />
                  </IconButton>
                </>
              )
            }
          </Toolbar>

        </AppBar>

        {/* <NavDrawer
          drawerOpened={this.state.drawerOpened}
          toggleDrawer={this.toggleDrawer}
        /> */}
      </div>
    );
  }
}


export default withRouter((withStyles(styles)(NavBar)));
