import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopy from '@material-ui/icons/FileCopy';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    marginTop: 0,
    
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    margin: 10,
    overflow: "auto",
    height: "100%",
    maxHeight: 150,
  },
  button: {
    margin: theme.spacing(1),
  },
  errorColor: {
    background: 'red',
  },
  normalColor: {
    background: 'white',
  }
});



const header = [
  {
    name: "Account",
    prop: "account"
  },
  {
    name: "Discount",
    prop: "discount"
  },
  {
    name: "Amount",
    prop: "amount"
  },
  {
    name: "Description",
    prop: "description"
  },
  {
    name: "Tax Category",
    prop: "taxCategory"
  },
  {
    name: "Tax Amount",
    prop: "taxAmount"
  },
  {
    name: "Actions",
    prop: "actions"
  }
];



class NewTable extends Component {


  deleteHandle = (event) => {
    event.preventDefault();
    console.log("press delete Button")
  }

  editHandle = (event) => {
    event.preventDefault();
    console.log("press edit Button")
  }

  render() {

    const { classes } = this.props;
    const row = (x, i, header) => {

      const dataHeaders = Object.assign([], header);
      dataHeaders.pop();
    
      const accountTable = {
        ...x
      };
      return (<TableRow key={`tr-${i}`}>

        {dataHeaders.map((y, k) =>
          <TableCell key={`trc-${k}`}>
            {accountTable[y.prop]["value"]}
          </TableCell>
        )}
        <TableCell>
          <Button variant="outlined" color="primary" aria-label="Edit" onClick={() => this.props.accountEdit(i)}>
            <EditIcon />
          </Button>

          <Button variant="outlined" color="primary" aria-label="Clone" onClick={() => this.props.accountClone(i)}>
            <FileCopy />
          </Button>

          <Button variant="outlined" color="primary" aria-label="Delete" onClick={() => this.props.accountRemove(i)}>
            <DeleteIcon />
          </Button>

          {/* <Button variant="outlined" color="primary" aria-label="Edit" onClick={this.editHandle}>
            <EditIcon />
          </Button> */}
        </TableCell>

      </TableRow>
      )

    }


    return (
      <Paper className={classes.paper}>
        <Table >
          <TableHead>
            <TableRow>

              {header.map((x, i) =>
                <TableCell key={`thc-${i}`}>
                  {x.name}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.data.map((x, i) => row(x, i, header))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(NewTable);