import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CodeEntry from '../CodeEntry/CodeEntry';
import NewTable from '../../Component/Table/NewTable';
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../Store/actions/index'
import { updateObject, checkValidity } from '../../shared/utility'
import Spinner from '../../Component/UI/Spinner/Spinner'
import DropZone from '../../Component/DropZone/DropZone'
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
// import MaskedInput from 'react-text-mask';
// import classes2 from './CheckRequest.module.css';
// import NumberFormat from 'react-number-format';

//import createNumberMask from 'text-mask-addons/dist/createNumberMask'
//import Input from '@material-ui/core/Input';


const formatDate = () => {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}


const CheckRequest = (props) => {

  const request = useSelector(state => state.request.newRequest);

  console.log("request: ", request);

  let initialRequestForm = {
    invoice: {
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      disabled: false,
      helpertext: ""
    },
    invoiceDate: {
      value: formatDate(),
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      disabled: false,
      helpertext: ""
    },
    dueDate: {
      value: formatDate(),
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      disabled: false,
      helpertext: ""
    },
    amount: {
      value: "",
      validation: {
        required: true,
        moreThan: 0
      },
      valid: false,
      touched: false,
      disabled: false,
      helpertext: ""
    },
    currency: {
      value: "USD",
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      disabled: false,
      helpertext: ""
    },
    type: {
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      disabled: false,
      helpertext: ""
    },
    po: {
      value: '',
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      disabled: false,
      helpertext: ""
    },
    explanation: {
      value: "",
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      disabled: false,
      helpertext: ""
    },
    requestor: {
      value: "",
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      disabled: true,
      helpertext: ""
    },
    approver: {
      value: "",
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      disabled: true,
      helpertext: ""
    },

  };

  useEffect(() => {
    if (request) {
      handleInputChange(request.invoice, "invoice");
      handleInputChange(request.po, "po");
      handleInputChange(request.invoiceDate, "invoiceDate");
      handleInputChange(request.dueDate, "dueDate");
      handleInputChange(request.currency, "currency");
      handleInputChange(request.amount, "amount");
      handleInputChange(request.requestor, "requestor");
      handleInputChange(request.approver, "approver");
      handleInputChange(request.explanation, "explanation");

      // setFiledocs(filedocs)
    }
  });

  const [filedocs, setFiledocs] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);
  const [lineIsValid, setLineIsValid] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [editAccountIndex, setEditAccountIndex] = useState(-1);
  const [requestForm, setRequestForm] = useState(initialRequestForm)
  const selected = useSelector(state => state.vendor.selectedVendor);
  const vendorobj = useSelector(state => state.vendor.vendor);
  const loading = useSelector(state => state.vendor.loading);
  const userID = useSelector(state => state.auth.userId);
  const token = useSelector(state => state.auth.token);

  // const requestVaild = useSelector(state => state.vendor.newRequestValid);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchVendorId(token, selected))
  }, [dispatch, selected, token]);

  const generateFormData = useCallback(() => {
    let formData = {};

    for (let formElementIdentifier in requestForm) {
      formData[formElementIdentifier] = requestForm[formElementIdentifier].value;
    }
    const d = new Date();
    const n = d.toISOString();
    formData["Created"] = n;
    formData["UserId"] = userID;
    formData["Status"] = "Request Submitted"
    formData["Vendor"] = vendorobj
    formData["Files"] = filedocs;

    let lineDatary = [];
    lineDatary = lineItems.map(acct => {
      let lineData = {};
      for (let formElementIdentifier in acct) {
        lineData[formElementIdentifier] = acct[formElementIdentifier].value;
      }
      return lineData;
    })
 
    formData["LineItems"] = lineDatary;

    return formData;
  }, [lineItems, requestForm, userID, vendorobj, filedocs]);

  const setIsRequestValid = useCallback((val) => {
    dispatch(actions.newRequestValid(val))
  }, [dispatch]);

  const submitReady = useCallback(() => {
    // console.log('form is Valid: ' + formIsValid + ',,, line is valid: ' + lineIsValid)
    if (formIsValid && lineIsValid) {
      // Generate Form Data
      const formData = generateFormData();
      
      // Dispatch the new request
      dispatch(actions.newRequest(formData))

      // Set the valid as true
      setIsRequestValid(true);
    }
    else {
      setIsRequestValid(false);
    }
  }, [dispatch, formIsValid, generateFormData, lineIsValid, setIsRequestValid]);

  useEffect(() => {
    submitReady();
  }, [formIsValid, lineIsValid, submitReady]);

  const checkIfAmountItemsAreDone = useCallback(() => {
    const totalAmount = parseFloat(requestForm.amount.value);

    if (totalAmount > 0) {
      const availableAmount = getAvailableAmount(totalAmount, lineItems);

      if (availableAmount === 0) {
        setLineIsValid(true);
        return;
      }
    }
    
    setLineIsValid(false);
  }, [lineItems, requestForm]);

  useEffect(() => {
    // Check if amount items are filled for invoice amount
    checkIfAmountItemsAreDone();
  }, [lineItems, requestForm, checkIfAmountItemsAreDone]);

  useEffect(() => {
    // Get the user 
    let url = 'https://checkrequest-20a0f.firebaseio.com/Users/-LqlfEYEguicfwi8_GIp/' + userID + ".json/?auth=" + token;
    const requestor = 'requestor';
    const approver = 'approver';
    axios.get(url)
      .then(res => {
        const requestorelement = updateObject(requestForm[requestor], { value: res.data.firstName + " " + res.data.lastName });
        const approverelement = updateObject(requestForm[approver], { value: res.data.approver });
        let updatedRequestForm1 = updateObject(requestForm, {
          [requestor]: requestorelement,
          [approver]: approverelement
        });

        setRequestForm(updatedRequestForm1);

      })
      .catch(err => {
        console.log("Failed Login ");
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, token]);

  //request reload on back button

  // useEffect(() => {
  //   console.log("formIsValid")
  //   if (request) {
  //     request.forEach(function (item, key) {
  //       console.log(item +'==>' + key)
  //     }
  //     )

  //   }
  // }, []);






  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      fontWeight: 800
    },
    input: {
      color: 'black'
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      margin: 10,
      background: 'linear-gradient(45deg, #D6EAF8  30%, #D6EAF8 90%)',
    },
    button: {
      margin: theme.spacing(1),
    },
    errorColor: {
      background: 'white',
    },
    normalColor: {
      background: 'white',
    },
    formControl: {
      margin: theme.spacing(2),
      minWidth: 175,
    },
  }));

  const onDrop = useCallback(acceptedFiles => {

    setFiledocs([...filedocs, ...acceptedFiles]);

  }, [filedocs])

  const removeFile = (file) => {

    const newFiles = [...filedocs]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFiledocs(newFiles)
  }


  const handleInputChange = (value, inputName) => {
    const updatedFormElement = updateObject(requestForm[inputName], {
      value: value,
      valid: true,
      touched: true
    });

    const updatedRequestForm = updateObject(requestForm, {
      [inputName]: updatedFormElement
    });


    updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.touched = true;
    updatedRequestForm[inputName] = updatedFormElement;

    let isFormValid = true;
    for (let inputIdentifier in updatedRequestForm) {
      isFormValid = updatedRequestForm[inputIdentifier].valid && isFormValid;
    }

    setRequestForm(updatedRequestForm);
    setFormIsValid(isFormValid);
  }

  const handleChange = (event, inputName, actualValue) => {
    handleInputChange(actualValue ? (event.target.value.length > 0 ? `${actualValue}` : '') : event.target.value, inputName);
  };

  const getAvailableAmount = (totalAmount, items) => {
    return totalAmount - items.reduce((total, item) => {
      return total + parseFloat(item.amount.value);
    }, 0);
  }

  const checkIfNewAmountIsAvailable = (newAmount) => {
    const totalAmount = parseFloat(requestForm.amount.value);
    const availableAmount = getAvailableAmount(totalAmount, lineItems);

    if ((availableAmount - newAmount) >= 0) {
      return true;
    }
    
    return false;
  }



  const removeAccountHandle = (dataIndex) => {
    const newData = [...lineItems];
    if (dataIndex !== -1) {
      // Stop editing mode when it is removed.
      if (dataIndex === editAccountIndex) {
        editAccountCancelHandle();
      }

      newData.splice(dataIndex, 1);
      setLineItems(newData)
    }
  }

  const cloneAccountHandle = (dataIndex) => {
    const newData = [...lineItems];
    if (dataIndex !== -1) {
      const currentItem = newData[dataIndex];
      const newAmount = parseFloat(currentItem.amount.value);

      // Check if total amount is over or not
      if (checkIfNewAmountIsAvailable(newAmount)) {
        newData.push(currentItem);
        setLineItems(newData)
      }
      else {
        dispatch(actions.showMessageSnackbar("Allocated Value Exceeds Invoice amount","SNACKBAR_MESSAGE_ERROR"));  
      }
    }
  }

  const editAccountHandle = (dataIndex) => {
    // Set the edit mode
    setEditAccountIndex(dataIndex);
  }

  const editAccountCancelHandle = () => {
    // Set back the edit mode to add mode
    setEditAccountIndex(-1);
  }

  const addOrUpdateAccountHandle = (dataarray) => {
    if (editAccountIndex > -1) {  // Edit mode
      const newData = [...lineItems];

      const currentItem = newData[editAccountIndex];
      const originalAmount = parseFloat(currentItem.amount.value);
      const newAmount = parseFloat(dataarray.amount.value);

      // Check if total amount is over or not
      if (newAmount <= originalAmount || checkIfNewAmountIsAvailable(newAmount - originalAmount)) {
        newData[editAccountIndex] = dataarray;
        setLineItems(newData)

        // Set back the edit mode to add mode
        setEditAccountIndex(-1);
      }
      else {
        dispatch(actions.showMessageSnackbar("Allocated Value Exceeds Invoice amount","SNACKBAR_MESSAGE_ERROR"));  
      }
    }
    else {  // Add mode
      setLineItems([...lineItems, dataarray]);
    }
  }

  const classes = useStyles();

  let formrend = <Spinner />;
  if (!loading && vendorobj) {
    formrend =
      <form className={classes.container} noValidate autoComplete="off">
        <Grid container spacing={2}>

          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography variant="body1">
                <b>PAY TO:  </b>
              </Typography>
              <Typography variant="body1" gutterBottom>
                ({vendorobj.ID ? vendorobj.ID : vendorobj.VendorID}) {vendorobj.Vendor}
              </Typography>
              <Typography variant="body1">
                <b>ADDRESS:</b>
              </Typography>
              <Typography variant="body1">
                {vendorobj.Address}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {vendorobj.City},    {vendorobj.State}  {vendorobj.Zip}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <DropZone
              files={filedocs}
              onDrop={onDrop}
              removeFile={removeFile}
            />
          </Grid>




          <Grid item xs={12}>

            <TextField
              id="invoice"
              label="Invoice Number"
              variant="outlined"
              margin="normal"
              required={requestForm.invoice.validation.required}
              error={!requestForm.invoice.valid && requestForm.invoice.touched}
              helperText={requestForm.invoice.helpertext}
              disabled={requestForm.invoice.disabled}
              InputProps={!requestForm.invoice.valid && requestForm.invoice.touched ?
                {
                  classes: {
                    input: classes.errorColor
                  }
                }
                :
                {
                  classes: {
                    input: classes.normalColor
                  }
                }

              }
              className={classes.textField}
              value={requestForm.invoice.value}
              onChange={e => handleChange(e, 'invoice')}
            />
            <TextField
              id="po"
              label="PO #"
              required={requestForm.po.validation.required}
              variant="outlined"
              className={classes.textField}
              error={!requestForm.po.valid && requestForm.po.touched}
              helperText={requestForm.po.helpertext}
              disabled={requestForm.po.disabled}
              value={requestForm.po.value}
              onChange={e => handleChange(e, 'po')}
              margin="normal"
              InputProps={!requestForm.po.valid && requestForm.po.touched ?
                {
                  classes: {
                    input: classes.errorColor
                  }
                }
                :
                {
                  classes: {
                    input: classes.normalColor
                  }
                }

              }
            />
            <TextField
              id="invoiceDate"
              label="Invoice Date"
              required={requestForm.invoiceDate.validation.required}
              variant="outlined"
              type="date"
              format={'MM/DD/YYYY'}
              error={!requestForm.invoiceDate.valid && requestForm.invoiceDate.touched}
              helperText={requestForm.invoiceDate.helpertext}
              disabled={requestForm.invoiceDate.disabled}
              className={classes.textField}
              value={requestForm.invoiceDate.value}
              onChange={e => handleChange(e, 'invoiceDate')}
              margin="normal"

              InputProps={!requestForm.invoiceDate.valid && requestForm.invoiceDate.touched ?
                {
                  classes: {
                    input: classes.errorColor
                  }
                }
                :
                {
                  classes: {
                    input: classes.normalColor
                  }
                }

              }
            />
            <TextField
              id="dueDate"
              label="Due Date "
              variant="outlined"
              required={requestForm.dueDate.validation.required}
              type="date"
              format={'MM/DD/YYYY'}
              error={!requestForm.dueDate.valid && requestForm.dueDate.touched}
              helperText={requestForm.dueDate.helpertext}
              disabled={requestForm.dueDate.disabled}
              className={classes.textField}
              value={requestForm.dueDate.value}
              onChange={e => handleChange(e, 'dueDate')}
              margin="normal"
              InputProps={!requestForm.dueDate.valid && requestForm.dueDate.touched ?
                {
                  classes: {
                    input: classes.errorColor
                  }
                }
                :
                {
                  classes: {
                    input: classes.normalColor
                  }
                }

              }
            />
            <FormControl variant="outlined" className={classes.formControl}>

              <InputLabel htmlFor="type-currency">Currency</InputLabel>
              <Select
                value={requestForm.currency.value}
                onChange={e => handleChange(e, 'currency')}
                inputProps={{
                  name: 'type',
                  id: 'type-currency',
                }}
              >
                <MenuItem value={'USD'}>USD</MenuItem>
                <MenuItem value={'CAD'}>CAD</MenuItem>
                <MenuItem value={'EUR'}>EUR</MenuItem>
                <MenuItem value={'HKD'}>HKD</MenuItem>
              </Select>

            </FormControl>




            <CurrencyTextField
              id="Amount"
              label="Amount to be paid"
              variant="outlined"
              required={requestForm.amount.validation.required}
              error={!requestForm.amount.valid && requestForm.amount.touched}
              helperText={requestForm.amount.helpertext}
              disabled={requestForm.amount.disabled}
              className={classes.textField}
              value={requestForm.amount.value}
              onChange={(e, value) => handleChange(e, 'amount', value)}
              margin="normal"
              InputProps={!requestForm.amount.valid && requestForm.amount.touched ?
                {
                  classes: {
                    input: classes.errorColor
                  }
                }
                :
                {
                  classes: {
                    input: classes.normalColor
                  }
                }

              }
            />





          </Grid>

          <Grid item xs={12}>
            <TextField
              id="requestor"
              label="Requestor"
              variant="outlined"
              error={!requestForm.requestor.valid && requestForm.requestor.touched}
              helperText={requestForm.requestor.helpertext}
              disabled={requestForm.requestor.disabled}
              className={classes.textField}
              value={requestForm.requestor.value}
              onChange={e => handleChange(e, 'requestor')}
              margin="normal"
              InputProps={!requestForm.requestor.valid && requestForm.requestor.touched ?
                {
                  classes: {
                    input: classes.errorColor
                  }
                }
                :
                {
                  classes: {
                    input: classes.normalColor
                  }
                }
              }
            />
            <TextField
              id="approver"
              label="Approver"
              variant="outlined"
              error={!requestForm.approver.valid && requestForm.approver.touched}
              helperText={requestForm.approver.helpertext}
              disabled={requestForm.approver.disabled}
              className={classes.textField}
              value={requestForm.approver.value}
              onChange={e => handleChange(e, 'approver')}
              margin="normal"
              InputProps={!requestForm.approver.valid && requestForm.approver.touched ?
                {
                  classes: {
                    input: classes.errorColor
                  }
                }
                :
                {
                  classes: {
                    input: classes.normalColor
                  }
                }

              }
            />



            <Grid item xs={12}>

              <TextField
                id="explanation"
                label="Explanation"
                variant="outlined"
                error={!requestForm.explanation.valid && requestForm.explanation.touched}
                required={requestForm.explanation.validation.required}
                helperText={requestForm.explanation.helpertext}
                disabled={requestForm.explanation.disabled}
                // className={classes.textField}
                multiline
                rowsMax="4"
                fullWidth={true}
                rows="1"
                //style={{ margin: 8, width: 900 }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={requestForm.explanation.value}
                onChange={e => handleChange(e, 'explanation')}
                margin="normal"
                InputProps={!requestForm.explanation.valid && requestForm.explanation.touched ?
                  {
                    classes: {
                      input: classes.errorColor
                    }
                  }
                  :
                  {
                    classes: {
                      input: classes.normalColor
                    }
                  }

                }
              />



            </Grid>


            <Grid item xs={10} />

            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                {/* <strong> USD {Number.parseFloat(requestForm.amount.value).toFixed(2)}</strong></p> */}
                {/* <b> Allocate  to a Specific Account(s) ${Number.parseFloat(requestForm.amount.value).toFixed(2)}</b> */}
              </Typography>
              <CodeEntry
                accountAdd={addOrUpdateAccountHandle}
                accountEditCancel={editAccountCancelHandle}
                amount={parseFloat(requestForm.amount.value).toFixed(2)}
                data={lineItems}
                editAccountIndex={editAccountIndex}
              />
              <NewTable
                data={lineItems}
                //        header={header}
                accountRemove={removeAccountHandle}
                accountClone={cloneAccountHandle}
                accountEdit={editAccountHandle}
              />
            </Grid>





            {/* <Totals data={data} /> */}
            <br />

          </Grid>
          <Grid item xs={12}>

          </Grid>
        </Grid>

      </form>
  }


  return (formrend);
}


export default (CheckRequest);