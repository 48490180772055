import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import NavBar  from "../../Navigation/Topbar/NavBar";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import {  useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import * as actions from '../../../Store/actions/index'


const Layout = props=> {
    
    function Copyright() {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.oappsnet.com/">
              oAppsNet
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }


    const useStyles = makeStyles(theme => ({
          layout: {
          width: 'auto',
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          [theme.breakpoints.up(1920 + theme.spacing(2) * 2)]: {
            width: 1920,
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        },
        paper: {
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3),
          padding: theme.spacing(2),
          [theme.breakpoints.up(1920 + theme.spacing(2) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
          },
        },
                
      }));

      const dispatch = useDispatch();

      // useEffect(() => {
      //     dispatch(actions.authCheckState())
      // }, []);

      const isAuthenticated = useSelector(state => state.auth.token) !==null;
    //  const isAuthWarging= useSelector(state => state.auth.authWarning);
      useEffect(() => {
        dispatch(actions.authCheckState())
    }, [dispatch, isAuthenticated]);

      const classes = useStyles();
      // const isAuthenticated = useSelector(state => state.auth.token) !==null;
      // const isAuthWarging= useSelector(state => state.auth.authWarning);
      let authRedirect = null;
      if (!isAuthenticated) {
        authRedirect = <Redirect to="/Login" />  
       }

      

return(
    <React.Fragment>
     <CssBaseline/>   
     <NavBar 
          isAuth={isAuthenticated}
     /> 
     <main className={classes.layout}>
        <Paper className={classes.paper}>
        {authRedirect}  
        {props.children}
        </Paper>
        <Copyright  />
      </main>
    </React.Fragment>
    );
    }



 



export default  Layout;
