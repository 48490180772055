import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from "react-redux";
import * as actions from '../../Store/actions/index'


export default function Logout() {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(actions.logout())
      });
    

   return <Redirect to="/Login" />  

}

