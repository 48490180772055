import * as actionTypes from '../actions/actionTypes';
import * as actions from '../../Store/actions/index'
import axios from 'axios';


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };


};


export const authSuccess = (token, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        resetMessage: null

    };


};


export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
       // error: error,
        resetMessage: null
    };
};

//

export const authResetSuccess = () => {
      return dispatch => {
        dispatch(actions.showMessageSnackbar("Email Sent",actionTypes.SNACKBAR_MESSAGE_SUCCESS)); 
       
         
    };


};

export const authResetFail = (error) => {
    return {
        type: actionTypes.AUTH_RESET_FAIL,
         error: error
    };
};
//

export const authWarning = (flag) => {
    return dispatch => {
    dispatch(actions.showMessageSnackbar("Logout 5 minutes",actionTypes.SNACKBAR_MESSAGE_WARNING)); 
    }
    // return {
    //     type: actionTypes.AUTH_WARNING,
    //     authWarning: flag
    // };
};

export const logout = () => {

    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('refresh');
     
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout =(expirationTime) => {

   const warningTime = expirationTime - (expirationTime * .10);
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
        setTimeout(() => {
            dispatch(authWarning(true));
         }, warningTime * 1000)

    };
};

export const auth = (email, password ) => {
    return dispatch => {
       dispatch(authStart());
        const authData ={
            email:email,
            password: password,
            returnSecureToken: true
        }
      
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBA76g2uKU5O1loo7N4W-z_dt5kLKQGgDA';
        axios.post(url, authData)        
        .then(response => {
         
 
            const expireationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000)
         
            localStorage.setItem('token',response.data.idToken);
            localStorage.setItem('expirationDate',expireationDate);
            localStorage.setItem('userId',response.data.localId);
            localStorage.setItem('refresh',response.data.refreshToken);
            dispatch(authSuccess(response.data.idToken, response.data.localId));
            dispatch(checkAuthTimeout(response.data.expiresIn))
        })
        .catch(err => {
       
            dispatch(authFail(err.response.data.error));
            dispatch(actions.showMessageSnackbar("Authorization Failed",actionTypes.SNACKBAR_MESSAGE_ERROR)); 
        })

    };

};

//=====

export const authRefesh = ( ) => {
    return dispatch => {
       dispatch(authStart());
      
       const authData = {
        grant_type:'refresh_token',
        refresh_token: new Date(localStorage.getItem('refresh'))
        }
     
      

        let url = 'https://securetoken.googleapis.com/v1/token?key=AIzaSyBA76g2uKU5O1loo7N4W-z_dt5kLKQGgDA'

        axios.post(url, authData)        
        .then(response => {
         
            const expireationDate = new Date(new Date().getTime() + response.data.expires_in * 1000)
            localStorage.setItem('token',response.data.id_token);
            localStorage.setItem('expirationDate',expireationDate);
            localStorage.setItem('userId',response.data.user_id);
            localStorage.setItem('refresh',response.data.refresh_token);


            dispatch(authSuccess(response.data.idToken, response.data.user_id));
            dispatch(checkAuthTimeout(response.data.expires_in))
        })
        .catch(err => {
       
            dispatch(authFail(err.response.data.error));
        })

    };

};

//====


export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.AUTH_REDIRECT_PATH,
        path: path

    }
}

export const authCheckState = () => {
   
    return dispatch => {
        const token = localStorage.getItem('token')
        if(!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');  
                dispatch(authSuccess(token,userId)) ; 
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())/1000));
            }
           

        }

    }

}



export const authReset = (email ) => {
    return dispatch => {
     
        const authDataReset ={
            email:email,
            requestType: "PASSWORD_RESET"          
        }
        // console.log("Auth Action Reset" + authDataReset);
     
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyBA76g2uKU5O1loo7N4W-z_dt5kLKQGgDA';
        axios.post(url, authDataReset)        
        .then(response => {
            dispatch(authResetSuccess());
         })
        .catch(err => {
       
            dispatch(authResetFail(err.response.data.error));
        })

    };

};

