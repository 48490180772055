import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../Store/actions/index'
import Spinner from '../../Component/UI/Spinner/Spinner'
import { makeStyles, lighten } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';
import Toolbar from '@material-ui/core/Toolbar';
import TablePagination from '@material-ui/core/TablePagination';
import { Link as RouterLink } from 'react-router-dom';

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  { id: 'request_id', numeric: false, disablePadding: true, label: 'Request Number' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'explaination', numeric: false, disablePadding: false, label: 'Explaination' },
  { id: 'invoice', numeric: false, disablePadding: false, label: 'Invoice' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Amount' },
  { id: 'vendor', numeric: false, disablePadding: false, label: 'Vendor' },
  { id: 'due_date', numeric: false, disablePadding: false, label: 'Due Date' },
  { id: 'requestor', numeric: false, disablePadding: false, label: 'Requestor' },
  { id: 'approver', numeric: false, disablePadding: false, label: 'Appover' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h5" id="tableTitle">
          List of Requests
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Approve">
            <IconButton aria-label="approve">
              <DoneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Withdraw">
            <IconButton aria-label="withdraw">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton aria-label="cancel">
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Export">
          <IconButton aria-label="export">
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function NewVendorTable(props) { 

  const classes = useStyles();
  const dispatch = useDispatch();
  const userID = useSelector(state => state.auth.userId);
  const token = useSelector(state => state.auth.token);
  const userRequests = useSelector(state => state.request.requests);
  const loadingRequests = useSelector(state => state.request.loadingRequests);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = userRequests.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  useEffect(() => {
    dispatch(actions.fetchRequests(token,userID))
  }, [dispatch, token, userID]);

  const isSelected = name => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userRequests.length - page * rowsPerPage);

  let requests = <Spinner/>;
  if ( !loadingRequests && userRequests) { 
    requests = <Paper className={classes.root}>
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={userRequests.length}
                    />
                    <TableBody>
                      {stableSort(userRequests, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
        
                          return (
                            <TableRow
                              hover
                              onClick={event => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                <RouterLink to={`/requests/${row.id}`}>{row.sequencenumber}</RouterLink>
                              </TableCell>
                              <TableCell align="left">{row.Status}</TableCell>
                              <TableCell align="left">{row.explanation}</TableCell>
                              <TableCell align="left">{row.invoice}</TableCell>
                              <TableCell align="left">{Number.parseFloat(row.amount).toFixed(2)}</TableCell>
                              <TableCell align="left">{row.Vendor.Vendor}</TableCell>
                              <TableCell align="left">{row.dueDate}</TableCell>
                              <TableCell align="left">{row.requestor}</TableCell>
                              <TableCell align="left">{row.approver}</TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={10} />
                        </TableRow>
                      )}

                      {
                      //   userRequests.map(row => (
                      //   <TableRow key={row.id}>
                      //     <TableCell component="th" scope="row">
                      //       {row.id}
                      //     </TableCell>
                      //     <TableCell align="left">{row.Status}</TableCell>
                      //     <TableCell align="left">{row.explanation}</TableCell>
                      //     <TableCell align="left">{row.invoice}</TableCell>
                      //     <TableCell align="left">{Number.parseFloat(row.amount).toFixed(2)}</TableCell>
                      //     <TableCell align="left">{row.Vendor.Vendor}</TableCell>
                      //     <TableCell align="left">{row.dueDate}</TableCell>
                      //     <TableCell align="left">{row.requestor}</TableCell>
                      //     <TableCell align="left">{row.approver}</TableCell>
                      //   </TableRow>
                      // ))
                    }
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={userRequests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>

  } else {
    requests = <Typography variant="body1" >No Requests Found</Typography>;
  }
      
  return (
    <div>
      {requests}
    </div>
  );
}
