import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility'

const initialState = {
    vendors: [],
    loading: false,
    selectedVendor: 0,
    newVendorValid: false,
    newVendor: null,
    vendor: null,
   
}



const fetchVendorsStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const fetchVendorsSuccess = (state, action) => {
    return updateObject(state, {  vendors: action.vendors, loading: false });
}

const fetchVendorsFail = (state, action) => {
    return updateObject(state, {  loading: false });
}


const fetchVendorIdStart = (state, action) => {
    return updateObject(state, { loading: true });
}


const fetchVendorIdSuccess = (state, action) => {
    return updateObject(state, {  vendor: action.vendor, loading: false });
}


const fetchVendorIdFail = (state, action) => {
    return updateObject(state, {  loading: false });
}

const selectVendor = (state, action) => {
    return updateObject(state, {  selectedVendor: action.selectedVendor });
}

const newVendor = (state, action) => {
    return updateObject(state, {    newVendor: action.newVendor });
}

const newVendorVaild = (state, action) => {
    return updateObject(state, {   newVendorValid: action.newVendorValid });
}

const newVendorStart = (state, action) => {
    return updateObject(state, {  loading: true });
}

const newVendorSuccess = (state, action) => {
    const newVendor = {
        ...action.vendorData,
        VendorID: action.VendorID

    };
    return updateObject(state, {  loading: false,  vendor: newVendor });
}


const reducer = (state = initialState, action) => {

    switch (action.type) {
        case  actionTypes.FETCH_VENDORS_START: return fetchVendorsStart(state, action);
        case  actionTypes.FETCH_VENDORS_SUCCESS : return fetchVendorsSuccess(state, action);        
        case  actionTypes.FETCH_VENDORS_FAIL: return fetchVendorsFail(state, action);             
        case  actionTypes.FETCH_VENDORID_START: return fetchVendorIdStart(state, action);
        case  actionTypes.FETCH_VENDORID_SUCCESS: return fetchVendorIdSuccess(state, action);
        case  actionTypes.FETCH_VENDORID_FAIL: return fetchVendorIdFail(state, action);
        case  actionTypes.SELECT_VENDOR: return selectVendor(state, action);         
        case  actionTypes.NEW_VENDOR: return newVendor(state, action);  
        case  actionTypes.NEW_VENDOR_VALID: return newVendorVaild(state, action);        
        case  actionTypes.CREATE_VENDOR_START: return  newVendorStart(state, action);
        case  actionTypes.CREATE_VENDOR_SUCCESS: return  newVendorSuccess(state, action);
       
        default:
            return state;
    }


};

export default reducer;