import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility'

const initialState = {
    segments: [],
    loading: false,
    error: null,
    seglook: []   
}


const fetchAcctSegStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
}

const fetchAcctSegSuccess = (state, action) => {
   return updateObject(state, {
        segments: action.segments,
        error: null,
        loading: false
    });
}

const fetchAcctSegFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

//

const fetchSegLookStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
}

const fetchSegLookSuccess = (state, action) => {
   return updateObject(state, {
        seglook: action.seglook,
        error: null,
        loading: false
    });
}

const fetchSegLookFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}



const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.FETCH_ACCTSEG_START: return fetchAcctSegStart(state, action);
        case actionTypes.FETCH_ACCTSEG_SUCCESS : return fetchAcctSegSuccess(state, action);
        case actionTypes.FETCH_ACCTSEG_FAIL: return fetchAcctSegFail(state, action);
        case actionTypes.FETCH_SEGLOOK_START: return fetchSegLookStart(state, action);
        case actionTypes.FETCH_SEGLOOK_SUCCESS : return fetchSegLookSuccess(state, action);
        case actionTypes.FETCH_SEGLOOK_FAIL: return fetchSegLookFail(state, action);
        default:
            return state;
    }


};

export default reducer;