import React from 'react';
import {useDropzone} from 'react-dropzone';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
 

function DropZone(props) {
   const maxSize = 1048576;
 


  const { isDragActive, getRootProps, getInputProps,  rejectedFiles } = useDropzone({
    onDrop:props.onDrop,
    minSize: 0,
    maxSize,
  });

  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  const fileslist = props.files.map((file)=> (
    <ListItem  key={file.path}>
    <ListItemAvatar>
      <Avatar>
        <FolderIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={file.path }
    
    />
     <ListItemSecondaryAction>
      <IconButton  edge="end"  aria-label="delete"   onClick={e =>props.removeFile(file)}>
        <DeleteIcon  />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>

  ));





  const useStyles = makeStyles(theme => ({
    box: {
      display: 'flex',
      flexWrap: 'wrap',
      background: '#97D2E1',
      border: 1,
      justifyContent: "center",
      fontWeight: 800,
      margin: '10px',
      padding: theme.spacing(1, 1)

    },
}))
  
 

  const classes = useStyles();
  return (
   
       <React.Fragment>
       <div {...getRootProps()}>
          <Box  className={classes.box}>
        <input {...getInputProps()} />
        {!isDragActive && ( 
          <div>
           <Icon className={classes.icon}>attach_file</Icon>
         Attach Supporting Files Here
          </div> )}
         {isFileTooLarge && (
          <div className="text-danger mt-2">
            File is too large.
          </div>
        )}
          </Box>
      </div>
        
    

<Paper style={{maxHeight: 100, overflow: 'auto'}}>
<List dense={true}>
          {fileslist}

</List>
  </Paper>
  </React.Fragment>
  );
}
export default DropZone;



 