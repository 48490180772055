import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility'


const initialState = {
    snackbarOpen: false,
    snackbarMessage: null,
    errorSnackbarOpen: false,
    infoSnackbarOpen: false,
    snackbarType: "success"
      }

const snackbarMessageSuccess = (state, action) => { 
    return updateObject(state, {
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarType: "success"
       });
}

const snackbarMessageError = (state, action) => { 
    return updateObject(state, {
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarType: "error"
       });
}

const snackbarMessageWarning = (state, action) => { 
    return updateObject(state, {
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarType: "warning"
       });
}

// const snackbarMessageInfo = (state, action) => { 
//     return updateObject(state, {
//         snackbarOpen: true,
//         snackbarMessage: action.message,
//         snackbarType: "info"
//        });
// }



const snackbarClear = (state, action) => { 
    return updateObject(state, {
        snackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false
       });
}


const reducer = (state = initialState, action) => {

    switch (action.type) {

              
        case actionTypes.SNACKBAR_MESSAGE_SUCCESS: return snackbarMessageSuccess(state, action);
        case actionTypes.SNACKBAR_MESSAGE_ERROR: return snackbarMessageError(state, action);
        case actionTypes.SNACKBAR_MESSAGE_WARNING: return snackbarMessageWarning(state, action);
        case actionTypes.SNACKBAR_MESSAGE_INFO: return snackbarMessageWarning(state, action);
        case actionTypes.SNACKBAR_CLEAR: return snackbarClear(state, action);         
               
        default:
            return state;
    }


};

export default reducer;
