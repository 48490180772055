import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from "react-redux";
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
//import MaskedInput from 'react-text-mask';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import { updateObject } from '../../shared/utility'
import * as actions from '../../Store/actions/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CompanyData from '../../Data/companies.json';
import CostCenterData from '../../Data/costcenters.json';
import AccountData from '../../Data/accounts.json';
import DepartmentData from '../../Data/departments.json';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
}));


const CodeEntry = (props) => {
  const classes = useStyles();
  
  const { editAccountIndex, data, amount } = props;
  const isEditing = (editAccountIndex !== undefined && editAccountIndex > -1) ? true : false;
  const editingItem = isEditing ? data[editAccountIndex] : null;

  const [accountAllocation, setaccountAllocation] = useState({
    account: {
      value: "",
      placeholder: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      disabled: false,
      helptext: ""
    },
    amount: {
      value: null,
      placeholder: props.amount,
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      disabled: false,
      helptext: ""
    },
    discount: {
      value: "",
      placeholder: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      disabled: false,
      helptext: ""
    },
    taxCategory: {
      value: "",
      placeholder: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      disabled: false,
      helptext: ""
    },
    taxAmount: {
      value: "",
      placeholder: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      disabled: false,
      helptext: ""
    },
    description: {
      value: "",
      placeholder: "",
      validation: {
        required: false
      },
      valid: false,
      touched: false,
      disabled: false,
      helptext: ""
    },
  })

  const dispatch = useDispatch();
  const [remainingAmount, setRemainingAmount] = useState(0.00);
  const [modalOpen, setModalOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [GLCompany, setGLCompany] = React.useState('');
  const [GLCostCenter, setGLCostCenter] = React.useState('');
  const [GLAccount, setGLAccount] = React.useState('');
  const [GLDepartment, setGLDepartment] = React.useState('');


  const companies = CompanyData.companies || [];

  const costCenters = CostCenterData.costcenters || [];

  const accounts = AccountData.accounts || [];

  const departments = DepartmentData.departments || [];

  const updateFieldsOnForm = useCallback((name, value) => {
    const updatedFormElement = updateObject(accountAllocation[name], {
      value: value,
      valid: true,
      touched: true
    });


    const updatedAllocationForm = updateObject(accountAllocation, {
      [name]: updatedFormElement
    });

    updatedFormElement.valid = checkValidity(name, updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.touched = true;
    updatedAllocationForm[name] = updatedFormElement;


    let validmesg = checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.valid = validmesg.isValid;
    updatedFormElement.helpertext = validmesg.message;
    updatedFormElement.touched = true;
    updatedAllocationForm[name] = updatedFormElement;

    setaccountAllocation(updatedAllocationForm);
  }, [accountAllocation]);

  

  useEffect(() => {
    if (editingItem) {
      setaccountAllocation(editingItem);
    }

    // Calculate the remaining amount
    let value = isNaN(amount) ? 0.00 : parseFloat(amount);
    for (const item of data) {
      value -= parseFloat(item['amount']['value'])
    }
    setRemainingAmount(value.toFixed(2));

  }, [amount, editingItem, data]);

  // const TextMaskCustom = (props) => {
  //   const { inputRef, ...other } = props;

  //   return (
  //     <MaskedInput
  //       {...other}
  //       ref={ref => {
  //         inputRef(ref ? ref.inputElement : null);
  //       }}
  //       mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
  //     //mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
  //     // placeholderChar={'-'}
  //     //  showMask
  //     />
  //   );
  // }


  const checkValidity = (name, value, rules) => {
    let isValid = true;
    let message = "";


    return { isValid: isValid, message: message };

  }

  const handleChange = name => event => {
    updateFieldsOnForm(name, event.target.value)
  };

  const handleFocus = () => {
    // Reset the GL account assignment
    if (accountAllocation.account.value && accountAllocation.account.value.length > 0) {
      const GLAccountOptionIDs = accountAllocation.account.value.split('-');

      GLAccountOptionIDs.forEach((optionId, index) => {
        switch(index) {
          case 0:
            let companyOption = companies.find(i => i.id === optionId)
            if (companyOption) {
              setGLCompany(companyOption.id);
            }
            break;
          case 1:
            let costCenterOption = costCenters.find(i => i.id === optionId)
            if (costCenterOption) {
              setGLCostCenter(costCenterOption.id);
            }
            break;
          case 2:
            let accountOption = accounts.find(i => i.id === optionId)
            if (accountOption) {
              setGLAccount(accountOption.id);
            }
            break;
          case 3:
            let departmentOption = departments.find(i => i.id === optionId)
            if (departmentOption) {
              setGLDepartment(departmentOption.id);
            }
            break;
          default:
            break;
        }
      })
    }
    else {
      setGLCompany('');
      setGLCostCenter('');
      setGLAccount('');
      setGLDepartment('');
    }
    

    // Open the modal
    setModalOpen(true);
  }

  const handleModalClose = () => {
    
    setModalOpen(false);
  };

  const handleModalDone = () => {
    const newGLAccountValue = `${GLCompany}-${GLCostCenter}-${GLAccount}-${GLDepartment}`;
    
    const newAllocation = Object.assign({}, accountAllocation);
    newAllocation.account.value = newGLAccountValue;
    setaccountAllocation(newAllocation);

    setModalOpen(false);
  };

  const handleGLSelectChange = (key, value) => {
    switch(key) {
      case 'company':
        setGLCompany(value);
        break;
      case 'costCenter':
        setGLCostCenter(value);
        break;
      case 'account':
        setGLAccount(value);
        break;
      case 'department':
        setGLDepartment(value);
        break;
      default:
        break;
    }
  };

  const clearForm = (placeHolderAmount) => {
    // Clear the value on the all inputs
    let newAllocation = JSON.parse(JSON.stringify(accountAllocation));

    for (let fieldIdentifier in newAllocation) {
      newAllocation[fieldIdentifier].valid = false
      newAllocation[fieldIdentifier].value = " "
      newAllocation[fieldIdentifier].touched = false
      newAllocation[fieldIdentifier].helptext = "No Error"
      
      if (fieldIdentifier === 'amount' && placeHolderAmount) {
        newAllocation[fieldIdentifier].placeholder = placeHolderAmount
      } else {
        newAllocation[fieldIdentifier].placeholder  = ""
      }
    }

    setaccountAllocation(newAllocation);


    // Clear the GL Account
    setGLCompany('');
    setGLCostCenter('');
    setGLAccount('');
    setGLDepartment('');
  }

  const cancelEditHandle = e => {
    e.preventDefault();

    // Clear the form
    clearForm();

    // Call the cancel function
    if (props.accountEditCancel)
      props.accountEditCancel();
  }

  const handleAmountAssignment = (isAddOrEdit) => {

    let availableAmount = isAddOrEdit 
      ? parseFloat(remainingAmount)                                       // In case of Add, just remaining amount
      : +remainingAmount + parseFloat(editingItem['amount']['value']);    // In case of Edit, sum the original amount with remaining amount
    let newAmount = parseFloat(accountAllocation.amount.value);

    // Check if new amount is valid number
    if (isNaN(newAmount) || newAmount <= 0) {
      dispatch(actions.showMessageSnackbar("Amount is not valid.","SNACKBAR_MESSAGE_ERROR"));  
      return;
    }

    // Check if new amount is less than available amount
    if (newAmount <= availableAmount) {
      // Update the allocation
      accountAllocation.amount.value= newAmount.toFixed(2);
      props.accountAdd(accountAllocation);

      // Clear the form
      clearForm(newAmount);
    }
    else {
      dispatch(actions.showMessageSnackbar("Allocated Value Exceeds Invoice amount","SNACKBAR_MESSAGE_ERROR"));  
    }
  }

  const editAccountHandle = e => {
    e.preventDefault();

    handleAmountAssignment(false);
  }

  const addAccountHandle = e => {
    e.preventDefault();

    handleAmountAssignment(true);
  };
  
  return (
    <div >

      {/* <FormControl >
          <InputLabel htmlFor="General-Ledger-Account">General Ledger Account</InputLabel>
          <Input
            value={accountAllocation.account.value}             
            onChange={handleChange('account')}
            id="General-Ledger-Account"            
            inputComponent={TextMaskCustom}
          />
        </FormControl> */}
       <b> Amount remaining to allocate {isNaN(amount) ?  'N/A' : Number.parseFloat(remainingAmount).toFixed(2) } </b> 
      <br></br>

      <FormControl variant="outlined" margin="dense">
        <InputLabel htmlFor="General-Ledger-Account1" >GL Account</InputLabel>
        <OutlinedInput
          id="General-Ledger-Account1"
          type="text"
          value={accountAllocation.account.value}
          onChange={handleChange('account')}
          style={{
            marginRight: 1,
            width: 150,
            paddingRight: 0
          }}
          labelWidth={90}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle the modal for GL account"
                onClick={handleFocus}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <TextField
        name="amount"
        variant="outlined"
        type="number"
        label="Amount"
        margin="dense"
        placeholder={isNaN(amount) ?  'N/A' : `${Number.parseFloat(remainingAmount).toFixed(2)}`}
        value={accountAllocation.amount.value ? accountAllocation.amount.value : ""}
        onChange={handleChange('amount')}
        style={{
          marginRight: 1,
          width: 150
        }}


      />

      <TextField
        name="description"
        variant="outlined"
        label="Description"
        margin="dense"
        value={accountAllocation.description.value}

        onChange={handleChange('description')}
        style={{
          marginLeft: 1,
          width: 350
        }}


      />


      <TextField
        name="discount"
        variant="outlined"
        type="number"
        label="Discount"
        margin="dense"
        value={accountAllocation.discount.value}

        onChange={handleChange('discount')}
        style={{
          marginRight: 1,
          width: 150
        }}


      />



      <TextField
        name="taxCategory"
        variant="outlined"
        label="Tax Category"
        margin="dense"
        value={accountAllocation.taxCategory.value}

        onChange={handleChange('taxCategory')}

      />




      <TextField
        name="taxAmount"
        variant="outlined"
        label="Tax Amount"
        type="number"
        margin="dense"
        value={accountAllocation.taxAmount.value}

        onChange={handleChange('taxAmount')}
        style={{
          marginRight: 1,
          width: 150
        }}




      />

      {
        !isEditing ? (

        <Button variant="outlined" color="primary" aria-label="Add" style={{ margin: 10 }} onClick={e => addAccountHandle(e)}>
          <AddIcon />ADD
          
        </Button>
        )
        : (
          <>
            <Button variant="outlined" color="primary" aria-label="Add" style={{ margin: 10 }} onClick={e => editAccountHandle(e)}>
              <EditIcon />Edit
            </Button>
            <Button variant="outlined" color="primary" aria-label="Add" style={{ margin: 10 }} onClick={e => cancelEditHandle(e)}>
              <CancelIcon />Cancel
            </Button>
          </>
        )
      }

      
      

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"GL Account Assignment"}</DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="max-width">Company</InputLabel>
              <Select
                value={GLCompany}
                onChange={(e) => handleGLSelectChange('company', e.target.value)}
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {
                  companies.map(company => 
                    <MenuItem key={company.id} value={company.id}>{`${company.id} | ${company.name}`}</MenuItem> 
                  )
                }
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="max-width">Cost Center</InputLabel>
              <Select
                value={GLCostCenter}
                onChange={(e) => handleGLSelectChange('costCenter', e.target.value)}
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {
                  costCenters.map(center => 
                    <MenuItem key={center.id} value={center.id}>{`${center.id} | ${center.name}`}</MenuItem>
                  )
                }
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="max-width">Account</InputLabel>
              <Select
                value={GLAccount}
                onChange={(e) => handleGLSelectChange('account', e.target.value)}
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {
                  accounts.map(account => 
                    <MenuItem key={account.id} value={account.id}>{`${account.id} | ${account.name}`}</MenuItem>
                  )
                }
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="max-width">Department</InputLabel>
              <Select
                value={GLDepartment}
                onChange={(e) => handleGLSelectChange('department', e.target.value)}
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                {
                  departments.map(dep => 
                    <MenuItem key={dep.id} value={dep.id}>{`${dep.id} | ${dep.name}`}</MenuItem>
                  )
                }
              </Select>
            </FormControl>


          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalDone} color="primary">
            Done
          </Button>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}


export default (CodeEntry);