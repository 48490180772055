import React, { useState  } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch,useSelector } from "react-redux";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import * as actions from '../../Store/actions/index'
import { updateObject, checkValidity } from '../../shared/utility'


const NewVendorForm = props=> {
 
  
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      fontWeight: 800
    },
    input: {
      color: 'black'
  },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      margin: 10,
        },
    button: {
      margin: theme.spacing(1),
    },
    errorColor: {
      background:'red',
    },
    normalColor: {
      background:'white',
    }
  }));
  
 
  const [vendorForm, setVendorForm]   = useState({
      Vendor: {
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        disabled: false,
        helpertext: ""
      },
      Address: {
        value: ' ',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        disabled: false,
        helpertext: ""
      },
      Address2: {
        value: ' ',
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        disabled: false,
        helpertext: ""
      },
      City: {
        value: ' ',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        disabled: false,
        helpertext: ""
      },
      State: {
        value: " ",
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        disabled: false,
        helpertext: ""
      },
      Zip: {
        value: " ",
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        disabled: false,
        helpertext: ""
      },
      Country: {
        value: " ",
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        disabled: false,
        helpertext: ""
      },
      ID: {
        value: "**PENDING*",
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        disabled: false,
        helpertext: ""
      },
      Note: {
        value: "",
        validation: {
          required: false
        },
        valid: true,
        touched: false,
        disabled: false,
        helpertext: ""
      },
     
  }
  )
 // const [formIsValid, setFormIsValid] = useState(false);
  const dispatch = useDispatch();



  const setValid = val => {
       dispatch(actions.newVendorValid(val))

  }


  const userID = useSelector(state => state.auth.userId);

const handleChange = (event,inputName) => {
  const updatedFormElement = updateObject(vendorForm[inputName], {
    value: event.target.value,
    valid:true,
    touched: true
  });

  const updatedVendorForm = updateObject(vendorForm, {
    [inputName]: updatedFormElement
  });
 
    updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
    updatedFormElement.touched = true;
    updatedVendorForm[inputName] = updatedFormElement;
 
    let formIsValid = true;
    for (let inputIdentifier in updatedVendorForm) {
           formIsValid = updatedVendorForm[inputIdentifier].valid && formIsValid;
    }

  setVendorForm(updatedVendorForm);
 // setFormIsValid(formIsValid);  
  
  setValid(formIsValid);
  const formData = {};
        for (let formElementIdentifier in updatedVendorForm) {
         
          formData[formElementIdentifier] = updatedVendorForm[formElementIdentifier].value;
         
          }  
  const d = new Date();
  const n = d.toISOString();        
  formData["Created"] = n;
  formData["Status"] = "Pending"
  formData["UserId"] = userID;
        //  console.log("user ID" + userID)
  if (formIsValid) {
        dispatch(actions.newVendor(formData))
  }
 

}

const classes = useStyles();
 


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
       New Vendor Setup Request
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
        <TextField
              id="Vendor"
              label="Vendor Name"
              variant="outlined"   
              required ={vendorForm.Vendor.validation.required }
              error  = {!vendorForm.Vendor.valid && vendorForm.Vendor.touched }
              helperText  = {vendorForm.Vendor.helpertext }
              disabled  = {vendorForm.Vendor.disabled }
              className={classes.textField}
              value={vendorForm.Vendor.value}
              onChange={e => handleChange(e, 'Vendor')}
              fullWidth
              autoComplete="Vender"
              margin="normal"
               InputProps={ !vendorForm.Vendor.valid && vendorForm.Vendor.touched ?
                {
                classes: {
                    input: classes.errorColor
                }
            }
            :
            {
              classes: {
                  input: classes.normalColor
              }}

            }
               
            />
        </Grid>
        <Grid item xs={12} sm={6}>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
                  required ={vendorForm.Address.validation.required }
            id="Address"
            name="Address"
            label="Address line"
            variant="outlined"  
            error  = {!vendorForm.Address.valid && vendorForm.Address.touched }
            helperText  = {vendorForm.Address.helpertext }
            disabled  = {vendorForm.Address.disabled }
            className={classes.textField}
            value={vendorForm.Address.value}
            onChange={e => handleChange(e, 'Address')}
            fullWidth
            margin="normal"
             InputProps={ !vendorForm.Address.valid && vendorForm.Address.touched ?
              {
              classes: {
                  input: classes.errorColor
              }
          }
          :
          {
            classes: {
                input: classes.normalColor
            }}

          }

          />
        </Grid>
        <Grid item xs={12} >
          
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
             required ={vendorForm.Address2.validation.required }
            id="Address2"
            name="Address2"
            label="Address line 2"
            fullWidth
            variant="outlined"  
            error  = {!vendorForm.Address2.valid && vendorForm.Address2.touched }
            helperText  = {vendorForm.Address2.helpertext }
            disabled  = {vendorForm.Address2.disabled }
            className={classes.textField}
            value={vendorForm.Address2.value}
            onChange={e => handleChange(e, 'Address2')}
             margin="normal"
             InputProps={ !vendorForm.Address2.valid && vendorForm.Address2.touched ?
              {
              classes: {
                  input: classes.errorColor
              }
          }
          :
          {
            classes: {
                input: classes.normalColor
            }}

          }

          />
        </Grid>
        <Grid item xs={12} >
          
          </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
             required ={vendorForm.City.validation.required }
            id="City"
            name="City"
            label="City"
            variant="outlined"  
            fullWidth
            error  = {!vendorForm.City.valid && vendorForm.City.touched }
            helperText  = {vendorForm.City.helpertext }
            disabled  = {vendorForm.City.disabled }
            className={classes.textField}
            value={vendorForm.City.value}
            onChange={e => handleChange(e, 'City')}
            margin="normal"
             InputProps={ !vendorForm.City.valid && vendorForm.City.touched ?
              {
              classes: {
                  input: classes.errorColor
              }
          }
          :
          {
            classes: {
                input: classes.normalColor
            }}

          }

          />
        </Grid>
        <Grid item xs={6} sm={3}>
           <TextField
            required ={vendorForm.State.validation.required }
            id="State"
            name="State"
            label="State/Province/Region" 
            width="200"
            variant="outlined"  
            error  = {!vendorForm.State.valid && vendorForm.State.touched }
            helperText  = {vendorForm.State.helpertext }
            disabled  = {vendorForm.State.disabled }
            className={classes.textField}
            value={vendorForm.State.value}
            onChange={e => handleChange(e, 'State')}
            margin="normal"
            fullWidth
             InputProps={ !vendorForm.State.valid && vendorForm.State.touched ?
              {
              classes: {
                  input: classes.errorColor
              }
          }
          :
          {
            classes: {
                input: classes.normalColor
            }}

          }

          />


          
        </Grid>
        <Grid item xs={12} sm={6}>
        </Grid>

        <Grid item xs={6} sm={3}>
          
          <TextField
             required ={vendorForm.Zip.validation.required }
            id="Zip"
            name="Zip"
            label="Zip / Postal code"
            variant="outlined"  
            error  = {!vendorForm.Zip.valid && vendorForm.Zip.touched }
            helperText  = {vendorForm.Zip.helpertext }
            disabled  = {vendorForm.Zip.disabled }
            className={classes.textField}
            value={vendorForm.Zip.value}
            fullWidth
            onChange={e => handleChange(e, 'Zip')}
            margin="normal"
             InputProps={ !vendorForm.Zip.valid && vendorForm.Zip.touched ?
              {
              classes: {
                  input: classes.errorColor
              }
          }
          :
          {
            classes: {
                input: classes.normalColor
            }}

          }

          />


        </Grid>
        <Grid item xs={6} sm={3}>
        <TextField
               required ={vendorForm.Country.validation.required }
            id="Country"
            name="Country"
            label="Country"
            variant="outlined"  
            error  = {!vendorForm.Country.valid && vendorForm.Country.touched }
            helperText  = {vendorForm.Country.helpertext }
            disabled  = {vendorForm.Country.disabled }
            className={classes.textField}
            value={vendorForm.Country.value}
            fullWidth
            onChange={e => handleChange(e, 'Country')}
            margin="normal"
             InputProps={ !vendorForm.Country.valid && vendorForm.Country.touched ?
              {
              classes: {
                  input: classes.errorColor
              }
          }
          :
          {
            classes: {
                input: classes.normalColor
            }}

          }

          />
        </Grid>
        <Grid item xs={12} sm={6}>
       
        </Grid>
        <Grid item xs={12}>
        <TextField
                required ={vendorForm.Note.validation.required }
                id="Note"
                label="Note"
                variant="outlined"
                error={!vendorForm.Note.valid && vendorForm.Note.touched}
                helperText={vendorForm.Note.helpertext}
                disabled={vendorForm.Note.disabled}
                className={classes.textField}
                multiline
                rowsMax="4"
                fullWidth
                rows="2"
               
                InputLabelProps={{
                  shrink: true,
                }}
                value={vendorForm.Note.value}
                onChange={e => handleChange(e, 'Note')}
                margin="normal"
                InputProps={!vendorForm.Note.valid && vendorForm.Note.touched ?
                  {
                    classes: {
                      input: classes.errorColor
                    }
                  }
                  :
                  {
                    classes: {
                      input: classes.normalColor
                    }
                  }

                }
              />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default   (NewVendorForm);