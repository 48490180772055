import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Container from '@material-ui/core/Container';
import * as actions from '../../Store/actions/index'
import { useDispatch, useSelector } from "react-redux";
import { checkValidity, updateObject } from '../../shared/utility'
import Spinner from '../../Component/UI/Spinner/Spinner'
import { Redirect } from 'react-router-dom';
 
const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
}));

export default function SignIn() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.loading);
    const error = useSelector(state => state.auth.error);
    const isAuthenticated = useSelector(state => state.auth.token) !== null;
    const authRedirectPath = useSelector(state => state.auth.authRedirectPath);
    const [authReset, setAuthReset] = useState(false);
    const resetMessage = useSelector(state => state.auth.resetMessage);
    const password = 'password'


    const [authForm, setAuthForm] = useState({
        email: {
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            disabled: false,
            helpertext: ""
        },
        password: {
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false,
            disabled: false,
            helpertext: ""
        },

    });

    const [formValid, setFormValid] = useState(false);

    let authRedirect = null;
    if (isAuthenticated) {
        authRedirect = <Redirect to={authRedirectPath} />
    }

     
    const onAuthHandle = () => {
       console.log("onAuthHandle " + authForm.email.value + "  " + authForm.password.value)
        dispatch(actions.auth(authForm.email.value, authForm.password.value))
    }

    const onAuthReset = () => {
     //   console.log("onAuthHandle reset")
        setAuthReset(true);
        const updatedFormElement = updateObject(authForm['password'], {
            valid: true,
         });

        const updatedAuthForm = updateObject(authForm, {
            [password]: updatedFormElement
        });

 
        setAuthForm(updatedAuthForm);
        // dispatch(actions.auth(authForm.email.value, authForm.password.value))
    }
 
    //onAuthResetSubmitHandl
    const onAuthResetSubmitHandle = () => {
       // console.log("onAuthHandle reset submitted")
         dispatch(actions.authReset(authForm.email.value))
         setAuthReset(false);
         const updatedFormElement = updateObject(authForm['password'], {
            valid: false,
         });

        const updatedAuthForm = updateObject(authForm, {
            [password]: updatedFormElement
        });
        setAuthForm(updatedAuthForm);
    }

    const handleChange = (event, inputName) => {
        event.preventDefault();
        const updatedFormElement = updateObject(authForm[inputName], {
            value: event.target.value,
            valid: true,
            touched: true
        });

        const updatedAuthForm = updateObject(authForm, {
            [inputName]: updatedFormElement
        });

        updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation);
           
        updatedFormElement.touched = true;
        updatedAuthForm[inputName] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedAuthForm) {
            formIsValid = updatedAuthForm[inputIdentifier].valid && formIsValid;
        }
        if (formIsValid) {
            setFormValid(true)
        }

        setAuthForm(updatedAuthForm);



		}
		
		const handleKeyPress = (ev) => {
			if (ev.key === 'Enter') {
				ev.preventDefault();

				if (formValid) {
					onAuthHandle();
				}
			}
		}

    if (resetMessage ) {
        console.log("Reset " + resetMessage.message)
    }



    let loginform = <div className={classes.paper}>
        <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
            Sign in
    </Typography>
        <form className={classes.form} >
            <TextField
                variant="outlined"
                margin="normal"
                value={authForm.email.value}
                onChange={e => handleChange(e, 'email')}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
								autoFocus
								onKeyPress={handleKeyPress}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={authForm.password.value}
                onChange={e => handleChange(e, 'password')}
                name="password"
                label="Password"
                type="password"
                id="password"
								autoComplete="current-password"
								onKeyPress={handleKeyPress}
            />
            <Button variant="contained" color="primary" className={classes.button}
                disabled={!formValid}
                onClick={onAuthHandle} >
                SIGN IN
                <Icon className={classes.rightIcon}>send</Icon>
            </Button>

            <Grid container>
                <Grid item xs>
                    <Button href="#text-buttons" className={classes.button} onClick={onAuthReset}>
                        Forgot password?
             </Button>

                </Grid>
                <Grid item xs></Grid>
                <Grid item >
                    {loading ? <Spinner /> : null}
                    {error ?
                        <Typography component="h3" variant="h5">
                            Error: {error.message}
                        </Typography>

                        : null}

                    {resetMessage ?
                            <Typography component="h3" variant="h5">
                                Message: {resetMessage.message}
                            </Typography>

                            : null}    
                </Grid>
            </Grid>
        </form>


    </div>

    if (authReset) {
        loginform =  <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                  <SubscriptionsIcon /> 
            </Avatar>
            <Typography component="h1" variant="h5">
                Password Reset
    </Typography>
            <form className={classes.form}  >
                <TextField
                    variant="outlined"
                    margin="normal"
                    value={authForm.email.value}
                    onChange={e => handleChange(e, 'email')}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
               
                <Button variant="contained" color="primary" className={classes.button}
                    disabled={!formValid}
                    onClick={onAuthResetSubmitHandle} >
                    Submit
                <Icon className={classes.rightIcon}>send</Icon>
                </Button>

                <Grid container>
                    
                    <Grid item xs></Grid>
                    <Grid item >
                        {loading ? <Spinner /> : null}
                        {error ?
                            <Typography component="h3" variant="h5">
                                Error: {error.message}
                            </Typography>

                            : null}
                          {resetMessage ?
                            <Typography component="h3" variant="h5">
                                Error: {resetMessage.message}
                            </Typography>

                            : null}    
                     
                    
                            
                    </Grid>
                </Grid>
            </form>


        </div>
          }


    return (
        <Container component="main" maxWidth="xs">
            {authRedirect}
            <CssBaseline />
            {loginform}
        </Container>
    );
}