import * as actionTypes from '../actions/actionTypes';



// dispatch(showSuccessSnackbar("Success!"));

export const showMessageSnackbar = (message,snackbarType) => {
    
    return {
       type:  snackbarType, 
       message:message,
       snackbarType:snackbarType
    };
  };
  
  export const clearSnackbar = () => {
    return  {
     type: actionTypes.SNACKBAR_CLEAR
    };
    
  }; 