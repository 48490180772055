
import React from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../../Component/UI/Spinner/Spinner'
import { Paper, Button, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  pageTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  payPaper: {
    padding: theme.spacing(2),
  },
  attachPaper: {
    padding: theme.spacing(2),
  },
  noteTextField: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    margin: 5,
    background: 'linear-gradient(45deg, #D6EAF8  30%, #D6EAF8 90%)',
  },
}));
  

export default function RequestDetail(props) {
  const classes = useStyles();

  const userRequests = useSelector(state => state.request.requests);
  const loadingRequests = useSelector(state => state.request.loadingRequests);

  const { requestId } = props.match.params;

  const onClickedReturn = () => {
    props.history.push('/requests')
  }

  let request_detail = <Spinner/>;
  if ( !loadingRequests && userRequests) { 
    const request = userRequests.find(r => r.id === requestId);

    // If request is not available, then go back to the requests screen
    if (!request) {
      props.history.push('/requests')
      return request_detail;
    }

    console.log("request: ", request);
    
    // Define the request detail
    const fileslist = !request.Files
      ? <></>
      : request.Files.map((file)=> (
          <ListItem  key={file.path}>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={file.path }
            />
          </ListItem>
        ));

    request_detail = 
      <Grid container spacing={3}> 
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.pageTitle}>
            <CheckCircleIcon color="primary" />Approval Screen
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.payPaper}>
            <Typography variant="h6" component="p" gutterBottom color="textSecondary">
              Pay
            </Typography>

            <Typography variant="body1">
              PAY TO:  
            </Typography>
            <Typography variant="body1" gutterBottom>
              <b> ({request.Vendor.VendorID}) {request.Vendor.Vendor} </b>
            </Typography>

            <Typography variant="body1">
              ADDRESS:
            </Typography>
            <Typography variant="body1">
              <b>    {request.Vendor.Address} </b>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <b> {request.Vendor.City},    {request.Vendor.State}  {request.Vendor.Zip} </b>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.attachPaper}>
            <Typography variant="h6" component="p" gutterBottom color="textSecondary">
              Attached Files
            </Typography>
            {
              request.Files && request.Files.length > 0
              ? (
                <List dense={true}>
                  {fileslist}
                </List>
              )
              : (
                <Typography variant="body1">
                  N/A
                </Typography>
              )
            }

            
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <hr/>
        </Grid>

        <Grid item sm={12} md={7}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    INVOICE
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    <b>{request.invoice} </b>
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    DUE DATE: 
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <b> {request.dueDate} </b>
                  </Typography>
                </Grid>
                  
                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    INVOICE DATE: 
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <b> {request.invoiceDate} </b>
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    CURRENCY: 
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <b> {request.currency} </b>
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    AMOUNT TO BE PAID:  
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                  <b>  {request.amount} </b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.payPaper}>
                <Typography variant="h6" component="p" gutterBottom color="textSecondary">
                  Explanation
                </Typography>

                <Typography variant="body1" gutterBottom>
                  {request.explanation.length > 0 ? (
                    <b>{request.explanation}</b>
                  )
                  : (
                    "N/A"
                  )
                  }
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.payPaper}>
                <Table className={classes.tableLineItems} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Line</TableCell>
                      <TableCell>GL Account</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell align="right">Discount</TableCell>
                      <TableCell align="right">Tax Category</TableCell>
                      <TableCell align="right">Tax Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      request.LineItems
                      ? request.LineItems.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell>{row.account}</TableCell>
                          <TableCell align="right">{row.amount}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell align="right">{row.discount}</TableCell>
                          <TableCell align="right">{row.taxCategory}</TableCell>
                          <TableCell align="right">{row.taxAmount}</TableCell>
                        </TableRow>
                      ))
                      : null
                    }
                  </TableBody>
                </Table>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <TextField 
                label="Note"
                autoFocus
                variant="outlined"
                margin="normal"
                className={classes.noteTextField}
              />
            </Grid>
        
          </Grid>
        </Grid>

        <Grid item sm={12} md={2}>
          <Button variant="contained" color="primary" className={classes.button}>
            Add Approver
          </Button>
        </Grid>
        <Grid item sm={12} md={3}>
          <Grid container direction="row">
            <Grid container alignItems="center">
              <PersonIcon fontSize="large"/>
              <Typography variant="body1" component="div">
                Request: <b>{request.requestor}</b>
              </Typography>
            </Grid>

            <Grid item>
              <AssignmentReturnedIcon fontSize="large"/>
            </Grid>

            <Grid container alignItems="center">
              <PersonIcon fontSize="large"/>
              <Typography variant="body1" component="div">
                Approver: <b>{request.approver}</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="default" className={classes.button} onClick={onClickedReturn}>
            Return
          </Button>
          <Button variant="contained" color="primary" className={classes.button}>
            Approve
          </Button>
          <Button variant="contained" color="secondary" className={classes.button}>
            Reject
          </Button>
        </Grid>
      </Grid>
    
  }

  return (
    <div>
      {request_detail}
    </div>
  );
}