import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility'



const initialState = {
    token: null,
    userId: null,
    firstName: null,
    lastName: null,
    role: null,
    approver: null,
    error: null,
    loading: false,
    resetMessage: null,
    authWarning: false,
    authRedirectPath: '/'
}

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
}

const authSuccess = (state, action) => {
   return updateObject(state, {
        token: action.idToken,
        userId: action.userId,
        error: null,
        resetMessage: null,
        loading: false
    });
}



const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}


const authResetSuccess = (state, action) => {
    return updateObject(state, {
        resetMessage: action.resetMessage        
     });
 }

const authResetFail = (state, action) => {
    return updateObject(state, {
        resetMessage: action.error      
    });
}

const authWarning = (state, action) => {
    return updateObject(state, {
        authWarning: action.authWarning      
    });
}

const authLogout = (state, action) => {
  //  state = undefined
  //return (state );
  return updateObject(state, { token: null, userId: null });
 //return updateObject(state, { token: null, userId: null });
}

const setAuthRedirectPath =  (state, action) => {
    return updateObject(state, {authRedirectPath: action.path  });
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_RESET_FAIL: return authResetFail(state, action);
        case actionTypes.AUTH_RESET_SUCCESS: return authResetSuccess(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.AUTH_WARNING: return authWarning(state, action);
      
        default:
            return state;
    }


};

export default reducer;