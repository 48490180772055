import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {  useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';


import { withRouter } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'New Request',
    description: ['New', 'Check Request'],
    buttonText: 'Get Started',
    buttonVariant: 'contained',
    buttonLink: '/vendor',
  },
  {
    title: 'Review Requests',
     description: [
      'Review submitted',
      'Check Requests' ,
       ],
    buttonText: 'Review',
    buttonVariant: 'contained',
    buttonLink: '/requests',
  },
 
];





 function MainChoice(props) {
  const classes = useStyles();
  const isAuthenticated = useSelector(state => state.auth.token) !==null;

  let authRedirect = null;
  if (!isAuthenticated) {
    authRedirect = <Redirect to="/Login" />  
  }
  
  const handleCallToRouter =  (e,value) => {
    e.preventDefault();
    props.history.push(value)
  }

  return (
    <React.Fragment>
      
      {authRedirect}

        
      <Container maxWidth="md" component="main">
        <Grid container spacing={4} alignItems="flex-end">
          {tiers.map(tier => (
           
            <Grid item key={tier.title} xs={12} sm={6} md={6}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
        
                  className={classes.cardHeader}
                />
                <CardContent>
                 
                  <ul>
                    {tier.description.map(line => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button 
                  fullWidth variant={tier.buttonVariant} 
                  color="primary"
                  onClick={e =>  handleCallToRouter(e,tier.buttonLink )}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
     
    </React.Fragment>
  );
}
export default    withRouter(MainChoice);