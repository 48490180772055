export const FETCH_VENDORS_START = "FETCH_VENDORS_START"
export const FETCH_VENDORS_SUCCESS = "FETCH_VENDORS_SUCCESS"
export const FETCH_VENDORS_FAIL = "FETCH_VENDORS_FAIL"

export const FETCH_ACCTSEG_START = "FETCH_ACCTSEG_START"
export const FETCH_ACCTSEG_SUCCESS = "FETCH_ACCTSEG_SUCCESS"
export const FETCH_ACCTSEG_FAIL = "FETCH_ACCTSEG_FAIL"

export const FETCH_SEGLOOK_START = "FETCH_SEGLOOK_START"
export const FETCH_SEGLOOK_SUCCESS = "FETCH_SEGLOOK_SUCCESS"
export const FETCH_SEGLOOK_FAIL = "FETCH_SEGLOOK_FAIL"

export const FETCH_REQUESTS_START = "FETCH_REQUESTS_START"
export const FETCH_REQUESTS_SUCCESS = "FETCH_REQUESTS_SUCCESS"
export const FETCH_REQUESTS_FAIL = "FETCH_REQUESTS_FAIL"

export const SELECT_VENDOR = "SELECT_VENDOR"

export const NEW_VENDOR_VALID = "NEW_VENDOR_VALID"
export const NEW_VENDOR = "NEW_VENDOR"

export const NEW_REQUEST_VALID = "NEW_REQUEST_VALID"
export const NEW_REQUEST = "NEW_REQUEST"

export const FETCH_VENDORID_START = "FETCH_VENDORID_START"
export const FETCH_VENDORID_SUCCESS = "FETCH_VENDORID_SUCCESS"
export const FETCH_VENDORID_FAIL = "FETCH_VENDORID_FAIL"
 
export const FETCH_SEGMENTS_START = "FETCH_ASEGMENTS_START"
export const FETCH_SEGMENTS_SUCCESS = "FETCH_SEGMENTS_SUCCESS"
export const FETCH_SEGMENTS_FAIL = "FETCH_SEGMENTS_FAIL"
           
export const CREATE_VENDOR_START = "CREATE_VENDOR_START"
export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS"
export const CREATE_VENDOR_FAIL = "CREATE_VENDOR_FAIL"

export const CREATE_REQUEST_START = "CREATE_REQUEST_START"
export const CREATE_REQUEST_SUCCESS = "CREATE_REQUEST_SUCCESS"
export const CREATE_REQUEST_FAIL = "CREATE_REQUEST_FAIL"

export const CREATE_NEW_SEQ = "CREATE_NEW_SEQ"

export const CREATE_REQUESTLINES_START = "CREATE_REQUESTLINES_START"
export const CREATE_REQUESTLINES_SUCCESS = "CREATE_REQUESTLINES_SUCCESS"
export const CREATE_REQUESTLINES_FAIL = "CREATE_REQUESTLINES_FAIL"
 
 
export const AUTH_START = "AUTH_START"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAIL = "AUTH_FAIL"
export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const AUTH_REDIRECT_PATH = "AUTH_REDIRECT_PATH"
export const AUTH_REFESH_TOKEN = "AUTH_REFESH_TOKEN"
export const AUTH_WARNING= "AUTH_WARNING"

export const AUTH_RESET = "AUTH_RESET"
export const AUTH_RESET_FAIL = "AUTH_RESET_FAIL"
export const AUTH_RESET_SUCCESS = "AUTH_RESET_SUCCESS"

export const USER_START = "USER_START"
export const USER_SUCCESS = "USER_SUCCESS"
export const USER_FAIL = "USER_FAIL"

export const SNACKBAR_MESSAGE_SUCCESS = "SNACKBAR_MESSAGE_SUCCESS"
export const SNACKBAR_MESSAGE_ERROR = "SNACKBAR_MESSAGE_ERROR"
export const SNACKBAR_MESSAGE_WARNING = "SNACKBAR_MESSAGE_WARNING"
export const SNACKBAR_MESSAGE_INFO = "SNACKBAR_MESSAGE_INFO"

export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR"

export const APPROVER_START= "APPROVER_START"
export const APPROVER_FAIL= "APPROVER_FAIL"
export const APPROVER_SUCCESS = "APPROVER_SUCCESS"
export const APPROVERALL_SUCCESS = "APPROVERALL_SUCCESS"


