import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Container/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk';
import vendorReducer from './Store/reducers/vendors'
import requestReducer from './Store/reducers/requests'
import authReducer from './Store/reducers/auth'
import segmentsReducer from './Store/reducers/segments'
import userReducer from './Store/reducers/users'
import approversReducer from './Store/reducers/approvers'
import uiReducer from './Store/reducers/ui'
 

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;


const rootReducer = combineReducers({
    vendor: vendorReducer,
    request: requestReducer,
    auth: authReducer,
    seg: segmentsReducer,
    user: userReducer,
    ui: uiReducer,
    apprv: approversReducer

});

 


const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>

);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
