import React, { Component } from 'react';
import './App.css';
import Layout from '../Component/UI/Layout/Layout'
import CheckRequest from '../Container/CheckRequest/CheckRequest'
import CustomerSearch from '../Container/CompanySearch/CompanySearch'
import NewVendorForm from '../Container/NewVendorForm/NewVendorForm'
import MainChoice  from '../Container/MainChoice/MainChoice'
import Login from '../Container/Login/Login'
import Logout from '../Container/Logout/Logout'
import Requests from '../Container/Requests/Requests'
import Request from '../Container/Requests/Request'
import Snackbar from '../Component/UI/SnackBar/SnackBar'

import { Route, Switch, Redirect  } from 'react-router-dom';

const routes = (
   <Switch>
    <Route path="/check" exact component={CheckRequest} />
    <Route path="/vendor" exact component={CustomerSearch} />
    <Route path="/newvendor" exact component={NewVendorForm} />
    <Route path="/mainchoice" exact component={MainChoice} />
    <Route path="/requests" exact component={Requests} />
    <Route path="/requests/:requestId" component={Request} />
    <Route path="/Login" exact component={Login} />
    <Route path="/Logout" exact component={Logout} />
    <Route path="/" component={MainChoice} />
    <Redirect to="/"  />/>
    </Switch>
 
);



class App extends Component {
  render() {
    
    return (
      <div>
         <Snackbar />
        <Layout >
           {routes}
        </Layout>
      </div>
    );
  }
}

export default App;