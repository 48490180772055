import React, { useState } from 'react'
import { TextField, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import * as actions from '../../Store/actions/index'
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import VendorSearchTable from './VendorSearchTable'
import NewVendorForm from '../NewVendorForm/NewVendorForm'
import Payment from '../CheckRequest/CheckRequest'
import Spinner from '../../Component/UI/Spinner/Spinner'
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
//import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FolderIcon from '@material-ui/icons/Folder';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
//import {SNACKBAR_MESSAGE_SUCCESS  } from '../../Store/actions/actionTypes'


const CompanySearch = (props) => {

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(1),
      width: 200,
    },
    button: {
      margin: theme.spacing(1),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    payPaper: {
      padding: theme.spacing(2),
    },
    attachPaper: {
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      margin: 5,
      background: 'linear-gradient(45deg, #D6EAF8  30%, #D6EAF8 90%)',
    },
    tableLineItems: {
      // minWidth: 300
    }
  }));


  const classes = useStyles();
  //const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const [isNewVender, setIsNewVendor] = useState(false);
  const [isRequestSaved, setIsRequestSaved] = useState(false);
  const dispatch = useDispatch();
  const vendors = useSelector(state => state.vendor.vendors);
  const selected = useSelector(state => state.vendor.selectedVendor);
  const loading = useSelector(state => state.vendor.loading);
  const vendorValid = useSelector(state => state.vendor.newVendorValid);
  const requestVaild = useSelector(state => state.request.newRequestValid);
  const vendor = useSelector(state => state.vendor.newVendor);
  const request = useSelector(state => state.request.newRequest);
  const token = useSelector(state => state.auth.token);
  const search = val => {
    dispatch(actions.fetchVendors(token, val))

  }

  // const setValid = val => {
  //   dispatch(actions.newVendorValid(val))

  // }

  let selectVender = false;
  if (selected) {
    selectVender = true
  }

  const onChangeHandler = async e => {

    e.preventDefault();
    search(e.target.value.toLowerCase());
    setValue(e.target.value);

  };

  const onNextHandle = e => {
    e.preventDefault();
    if (isStepOptional(activeStep + 1) && selected) {
      setActiveStep(prevActiveStep => prevActiveStep + 2);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }


  }

  const onBackHandle = e => {
    e.preventDefault();

    if (isStepOptional(activeStep - 1) && selected) {
      if ((activeStep - 2) === 0) {
        setIsNewVendor(false);
      }
      
      setActiveStep(prevActiveStep => prevActiveStep - 2);
    } else {
      if ((activeStep - 1) === 0) {
        setIsNewVendor(false);
      }

      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

  }

  const onNewVendorHandle = e => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  const onAddVendorHandle = e => {
    dispatch(actions.createVendor(token, vendor))
    dispatch(actions.newRequestValid(false));
    setIsNewVendor(true);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  const onSubmitPaymentHandle = e => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  const onNewRequestHandle = e => {
    dispatch(actions.createRequest(token, request))
    dispatch(actions.newRequestValid(false));
    props.history.push('/mainchoice')
    // setActiveStep(0);
  }

  const onSaveRequest = ()  => {
    setIsRequestSaved(true);
  }




  const renderResults = () => {
    let items = []

    //  if (Array.isArray(vendors) && vendors.length) {
    if (vendors) {
      items = [].concat(JSON.parse(JSON.stringify(vendors)));
      items.sort(function (a, b) {
        var nameA = a.Vendor.toUpperCase(); // ignore upper and lowercase
        var nameB = b.Vendor.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) { return -1; }
        if (nameA > nameB) { return 1; }
        return 0;
      });

    }

    return items;

  }

  const getSteps = () => {
    return ['Select Vendor/Payee to Pay', 'Enter New Vendor', 'Complete Payment Details', 'Review'];
  }

  const getStepName = () => {
    return ['Next', 'Add Supplier', 'Finalize Request', 'Submit Request'];
  }
  const isStepOptional = (step) => {
    return step === 1;
  }

  const isStepSkipped = (step) => {
    if (step === 1 && !isNewVender) {
      return true;
    }
    return false;
  }


  //const classes = useToolbarStyles();
  const steps = getSteps();
  const stepName = getStepName();

  const stephead = <Stepper activeStep={activeStep}>
    {steps.map((label, index) => {
      const stepProps = {};
      const labelProps = {};
      if (isStepOptional(index)) {
        labelProps.optional = <Typography variant="caption">Optional--only required for new Vendors/Payee setup</Typography>;
      }
      if (isStepSkipped(index)) {
        stepProps.completed = false;
      }
      return (
        <Step key={index} {...stepProps}>
          <StepLabel {...labelProps}>{label}</StepLabel>
        </Step>
      );
    })}
  </Stepper>


  

  let done = <div></div>

  if (request) {
    const fileslist = request.Files.map((file)=> (
      <ListItem  key={file.path}>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={file.path }
        />
      </ListItem>
    ));

    done = 
      <Grid container spacing={3}> 
        <Grid item xs={6}>
          <Paper className={classes.payPaper}>
            <Typography variant="h6" component="p" gutterBottom color="textSecondary">
              Pay
            </Typography>

            <Typography variant="body1">
              PAY TO:  
            </Typography>
            <Typography variant="body1" gutterBottom>
              <b> ({request.Vendor.VendorID}) {request.Vendor.Vendor} </b>
            </Typography>

            <Typography variant="body1">
              ADDRESS:
            </Typography>
            <Typography variant="body1">
              <b>    {request.Vendor.Address} </b>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <b> {request.Vendor.City},    {request.Vendor.State}  {request.Vendor.Zip} </b>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.attachPaper}>
            <Typography variant="h6" component="p" gutterBottom color="textSecondary">
              Attached Files
            </Typography>
            {
              request.Files.length > 0
              ? (
                <List dense={true}>
                  {fileslist}
                </List>
              )
              : (
                <Typography variant="body1">
                  N/A
                </Typography>
              )
            }

            
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <hr/>
        </Grid>

        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    INVOICE
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    <b>{request.invoice} </b>
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    DUE DATE: 
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <b> {request.dueDate} </b>
                  </Typography>
                </Grid>
                  
                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    INVOICE DATE: 
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <b> {request.invoiceDate} </b>
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="body1" gutterBottom>
                    CURRENCY: 
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <b> {request.currency} </b>
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body1" gutterBottom>
                    AMOUNT TO BE PAID:  
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                  <b>  {request.amount} </b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.payPaper}>
                <Typography variant="h6" component="p" gutterBottom color="textSecondary">
                  Explanation
                </Typography>

                <Typography variant="body1" gutterBottom>
                  {request.explanation.length > 0 ? (
                    <b>{request.explanation}</b>
                  )
                  : (
                    "N/A"
                  )
                  }
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.payPaper}>
                <Table className={classes.tableLineItems} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Line</TableCell>
                      <TableCell>GL Account</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell align="right">Discount</TableCell>
                      <TableCell align="right">Tax Category</TableCell>
                      <TableCell align="right">Tax Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {request.LineItems.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{row.account}</TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell align="right">{row.discount}</TableCell>
                        <TableCell align="right">{row.taxCategory}</TableCell>
                        <TableCell align="right">{row.taxAmount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
        
          </Grid>
        </Grid>

        <Grid item sm={12} md={4}>
          <Grid container direction="row">
            <Grid container alignItems="center">
              <PersonIcon fontSize="large"/>
              <Typography variant="body1" component="div">
                Request: <b>{request.requestor}</b>
              </Typography>
            </Grid>

            <Grid item>
              <AssignmentReturnedIcon fontSize="large"/>
            </Grid>

            <Grid container alignItems="center">
              <PersonIcon fontSize="large"/>
              <Typography variant="body1" component="div">
                Approver: <b>{request.approver}</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  }

  const checkIfSearchResultExisting = () => {
    return (!loading && value.length > 0 && (!vendors || vendors.length === 0)) ? false : true
  }

  const searchbody = <div>
    <TextField
      error={!checkIfSearchResultExisting()}
      helperText={!checkIfSearchResultExisting() ? "Vendor Not Found" : ""}
      id="standard-search"
      label="Search for Vendor  "
      autoFocus
      type="search"
      value={value}
      onChange={e => onChangeHandler(e)}
      // onKeyDown={e => this.keyPress(e)}
      margin="normal"
      placeholder="Search Vendor"
      className={classes.textField}
    />

    {loading ? <Spinner /> : null}

    {
      renderResults().length ?
        <VendorSearchTable
          rows={renderResults()}
          selected={selected}
        ></VendorSearchTable>

        : null
    }
  </div>

  const paymentBody = <Payment />;

  const footbody = <div>

    {activeStep > 0 && !(activeStep === steps.length) ?
      <Button variant="contained" color="default" className={classes.button}
        onClick={e => onBackHandle(e)}>
        <Icon className={classes.icon}>arrow_back</Icon>
        Back
                  </Button> : null}

    {activeStep === 0 ?
      <Button variant="contained" color="primary" className={classes.button}
        disabled={selectVender}
        onClick={e => onNewVendorHandle(e)}>
        New Vendor
        </Button> : null}


    {/* Select Vebndor */}
    {activeStep === 0 ?
      <Button variant="contained" color="primary" className={classes.button}
        disabled={!selectVender}
        onClick={e => onNextHandle(e)}>
        {stepName[activeStep]}
        <Icon className={classes.icon}>arrow_forward</Icon>
      </Button> : null
    }
    {/* Add Vendor */}
    {activeStep === 1 ?
      <Button variant="contained" color="primary" className={classes.button}
        disabled={!vendorValid}
        onClick={e => onAddVendorHandle(e)}>
        {stepName[activeStep]}
      </Button> : null
    }
    {/* Add Payment */}
    {activeStep === 2 ?
      <Button variant="contained" color="primary" className={classes.button}
        disabled={!requestVaild}
        onClick={e => onSubmitPaymentHandle(e)}>
        {stepName[activeStep]}
      </Button> : null
    }

    {activeStep === steps.length - 1 
      ? (
        <>
          <Button variant="contained" color="primary" className={classes.button}
            disabled={!requestVaild}
            onClick={e => onNewRequestHandle(e)}>
            {stepName[activeStep]}
          </Button>
          <Button variant="contained" color="default" className={classes.button}
            disabled={isRequestSaved}
            onClick={e => onSaveRequest(e)}
            >
            {isRequestSaved ? "Saved" : "Save Request"}
          </Button>
        </>
      )
      : null
    }
  </div>



  let body = null;
  switch (activeStep) {
    case 0:
      body = searchbody
      break;
    case 1:
      body = <NewVendorForm />
      break;
    case 2:
      body = paymentBody;
      break;
    case 3:
      body = done
      break;
    default:
      body = done
      break;

  }

  const renderResult = [stephead, body, footbody];
  return <>
          { renderResult.map((r, i) => <div key={i}>{r}</div>) }
        </>;
}


export default CompanySearch;
//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompanySearch));

