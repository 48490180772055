import * as actiontypes from './actionTypes';
import * as actions from '../../Store/actions/index'
import axios from 'axios';

export const selectVendor = (vendor) => {
    return {
        type: actiontypes.SELECT_VENDOR,
        selectedVendor: vendor

    };
};

export const newVendor = (vendor) => {
    return {
        type: actiontypes.NEW_VENDOR,
        newVendor: vendor

    };
};

export const newVendorValid = (valid) => {

    return {
        type: actiontypes.NEW_VENDOR_VALID,
        newVendorValid: valid

    };
};


export const fetchVendorsSuccess = (vendors) => {
    return {
        type: actiontypes.FETCH_VENDORS_SUCCESS,
        vendors: vendors

    };
};
 

export const fetchVendorsFail = (error) => {
    return {
        type: actiontypes.FETCH_VENDORS_FAIL,
        error: error

    };
};

export const fetchVendorsStart = (error) => {
    return {
        type: actiontypes.FETCH_VENDORS_START

    };
};

export const fetchVendorIdSuccess = (vendor) => {
    return {
        type: actiontypes.FETCH_VENDORID_SUCCESS,
        vendor: vendor

    };
};
 

export const fetchVendorIdFail = (error) => {
    return {
        type: actiontypes.FETCH_VENDORS_FAIL,
        error: error

    };
};

export const fetchVendorIdStart = (error) => {
    return {
        type: actiontypes.FETCH_VENDORS_START

    };
};


export const fetchVendorId = (token,vendor) => {
    return dispatch => {
     
 
        dispatch(fetchVendorIdStart());
        //const queryParms = '?auth=' + token + '&orderBy="userId"&equalTo="'+ userId +'"'
        const queryParms = vendor;
    
        
                  //https://checkrequest-20a0f.firebaseio.com/Vendors/360/.json   

        axios.get('https://checkrequest-20a0f.firebaseio.com/Vendors/'+ queryParms + '/.json?auth='  +token )
        .then(res => {
               dispatch(fetchVendorIdSuccess(res.data));
       })
        .catch(err => {
            dispatch(fetchVendorIdFail());
        });
    
       
    };
};


export const fetchVendors = (token,start) => {

    return dispatch => {
     
 
         dispatch(fetchVendorsStart());
         //const queryParms = '?auth=' + token + '&orderBy="userId"&equalTo="'+ userId +'"'
         const queryParms = start;
        
         
                   //https://checkrequest-20a0f.firebaseio.com/Vendors.json?auth=?orderBy="Vendor"&limitToFirst=20&startAt="A"   

         axios.get('https://checkrequest-20a0f.firebaseio.com/Vendors.json?auth=' + token + '&orderBy="vendorsearch"&limitToFirst=20&startAt="' + queryParms + '"' )
         .then(res => {
            const fetchedResults =[];
            for (let key in res.data) {
                    fetchedResults.push({
                          ...res.data[key],
                          id:key
                      });
              }
             dispatch(fetchVendorsSuccess(fetchedResults));
            
         })
         .catch(err => {
             dispatch(fetchVendorsFail());
         });
     
     
          
     };
 };

 export const createVendorSuccess = (id, vendorData) => {
    return {
        type: actiontypes.CREATE_VENDOR_SUCCESS,
        VendorID: id,
        vendorData: vendorData

    };
};

export const createVendorFail = (error) => {
    return {
        type: actiontypes.CREATE_VENDOR_FAIL,
        error: error

    };
}

export const createVendorStart = (vendorData) => {
    return {
        type: actiontypes.CREATE_VENDOR_START,
    };

};


 export const createVendor = (token,vendorData) => {

    return dispatch => {
     
        

         dispatch(createVendorStart());
          
         //https://checkrequest-20a0f.firebaseio.com/Vendors.json?orderBy="Vendor"&limitToFirst=20&startAt="A"   
          axios.post( 'https://checkrequest-20a0f.firebaseio.com/Vendors.json?auth=' + token, vendorData )
                  .then( response => {                   
                      dispatch(createVendorSuccess(response.data.name, vendorData));
                      dispatch(selectVendor(response.data.name))
                      dispatch(actions.showMessageSnackbar("Vendor Created",actiontypes.SNACKBAR_MESSAGE_SUCCESS));  
                      
                    } )
                  .catch( error => {
                      dispatch(createVendorFail(error));
                      dispatch(actions.showMessageSnackbar("Error Creating Vendor",actiontypes.SNACKBAR_MESSAGE_ERROR));  
                  } );
     
          
     };
 };
 
//  //====
//  export const createRequestSuccess = (id, requestrData) => {
//     return {
//         type: actiontypes.CREATE_REQUEST_SUCCESS,
//         RequestID: id,
//         requestData: requestrData

//     };
// };

// export const createRequestFail = (error) => {
//     return {
//         type: actiontypes.CREATE_REQUEST_FAIL,
//         error: error

//     };
// }

// export const createRequestStart = (requestData) => {
//     return {
//         type: actiontypes.CREATE_REQUEST_START,
//     };

// };


//  export const createRequest = (token,requestData) => {

//     return dispatch => {
     
     

//          dispatch(createRequestStart());
//                 axios.post( 'https://checkrequest-20a0f.firebaseio.com/Requests.json?auth=' + token, requestData )
//                   .then( response => {                   
//                       dispatch(createRequestSuccess(response.data.name, requestData));
//                       dispatch(actions.showMessageSnackbar("Request Created: " + response.data.name,actiontypes.SNACKBAR_MESSAGE_SUCCESS));  
                      
//                     } )
//                   .catch( error => {
//                       dispatch(createRequestFail(error));
//                       dispatch(actions.showMessageSnackbar("Error Creating Request",actiontypes.SNACKBAR_MESSAGE_ERROR));  
                      
                     
//                   } );
     
          
//      };
//  };
 

//  export const fetchRequests = (token,userId) => {

//     return dispatch => {
     
 
//          dispatch(fetchRequestsStart());
//          const queryParms = '?auth=' + token + '&orderBy="UserId"&equalTo="'+ userId +'"'
       
             
//               axios.get('https://checkrequest-20a0f.firebaseio.com/Requests.json' + queryParms  )
//          .then(res => {
//             const fetchedResults =[];
//             for (let key in res.data) {
//                     fetchedResults.push({
//                           ...res.data[key],
//                           id:key
//                       });
//               }
//           //    console.log(fetchedResults);
//              dispatch(fetchRequestsSuccess(fetchedResults));
            
//          })
//          .catch(err => {
//              dispatch(fetchRequestsFail());
//          });
     
     
          
//      };
//  };