import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility'

const initialState = {
    approversAll: [],
    loading: false,
    approver: null
 
   
}


const approverStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const approversAllSuccess = (state, action) => {
    return updateObject(state, {  approversAll: action.approversAll, loading: false });
}

const approverSuccess = (state, action) => {
    return updateObject(state, {  approver: action.approver, loading: false });
}

const approverFail = (state, action) => {
    return updateObject(state, {  loading: false });
}



const reducer = (state = initialState, action) => {

    switch (action.type) {
        case  actionTypes.APPROVER_START: return approverStart(state, action);
        case  actionTypes.APPROVERALL_SUCCESS : return approversAllSuccess(state, action);     
        case  actionTypes.APPROVER_SUCCESS : return approverSuccess(state, action);     
        case  actionTypes.APPROVER_FAIL: return approverFail(state, action);             
               
        default:
            return state;
    }


};

export default reducer;