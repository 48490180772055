import * as actiontypes from './actionTypes';
import * as actions from '../../Store/actions/index'
import { updateObject } from '../../shared/utility'
import axios from 'axios';
//import { number } from 'prop-types';

export const newRequest = (request) => {
    return {
        type: actiontypes.NEW_REQUEST,
        newRequest: request

    };
};

export const newRequestValid = (valid) => {

    return {
        type: actiontypes.NEW_REQUEST_VALID,
        newRequestValid: valid

    };
};

export const createRequestSuccess = (id, requestrData) => {
    return {
        type: actiontypes.CREATE_REQUEST_SUCCESS,
        RequestID: id,
        requestData: requestrData

    };
};

export const createRequestFail = (error) => {
    return {
        type: actiontypes.CREATE_REQUEST_FAIL,
        error: error

    };
}

export const createNextSequence = (seq) => {
    return {
        type: actiontypes.CREATE_NEW_SEQ,
        nextsequence: seq

    };
}


export const createRequestStart = (requestData) => {
    return {
        type: actiontypes.CREATE_REQUEST_START,
    };

};





export const fetchRequestsSuccess = (requests) => {
    return {
        type: actiontypes.FETCH_REQUESTS_SUCCESS,
        requests: requests

    };
};
 

export const fetchRequestsFail = (error) => {
    return {
        type: actiontypes.FETCH_REQUESTS_FAIL,
        error: error

    };
};

export const fetchRequestsStart = () => {
    return {
        type: actiontypes.FETCH_REQUESTS_START

    };
};


 export const createRequest = (token,requestData) => {

    return dispatch => {
       
        let next = 0;
        const queryParms = '?auth=' + token  
        axios.get('https://checkrequest-20a0f.firebaseio.com/sequence.json' + queryParms  )
        .then(res => {
           const fetchedResults =[];
           for (let key in res.data) {
                   fetchedResults.push({
                         ...res.data[key],
                         id:key
                     });
             }
             
             next = Number(res.data.nextnumber)+1
             console.log("TEST NEXT;;;" + next);
           //  newRequestData = newRequestData + {seqenceData:next}
           const newRequestData = updateObject(requestData, {
           "sequencenumber": next
          });
             dispatch(createRequestStart());        
             console.log("NEXT NUMBER:==>" + next);
                     axios.post( 'https://checkrequest-20a0f.firebaseio.com/Requests.json?auth=' + token, newRequestData )
                       .then( response => {                   
                           dispatch(createRequestSuccess(response.data.name, newRequestData));
                           dispatch(actions.showMessageSnackbar("Request Created: " + response.data.name,actiontypes.SNACKBAR_MESSAGE_SUCCESS));  
                           dispatch(putRequestNumber(token,next))
                           
                         } )
                       .catch( error => {
                           dispatch(createRequestFail(error));
                           dispatch(actions.showMessageSnackbar("Error Creating Request",actiontypes.SNACKBAR_MESSAGE_ERROR));  
                           
                          
                       } );
          
            

           
        })
        .catch(err => {
            dispatch(fetchRequestsFail());
        });


       
          
     };
 };
 
 
 
 export const fetchRequests = (token,userId) => {

    return dispatch => {
     
 
         dispatch(fetchRequestsStart());
         const queryParms = '?auth=' + token + '&orderBy="UserId"&equalTo="'+ userId +'"'
       
             
              axios.get('https://checkrequest-20a0f.firebaseio.com/Requests.json' + queryParms  )
         .then(res => {
            const fetchedResults =[];
            for (let key in res.data) {
                    fetchedResults.push({
                          ...res.data[key],
                          id:key
                      });
              }
          //    console.log(fetchedResults);
             dispatch(fetchRequestsSuccess(fetchedResults));
            
         })
         .catch(err => {
             dispatch(fetchRequestsFail());
         });
     
     
          
     };
 };

 

 export const putRequestNumber = (token,newreq) => {

    return dispatch => {
      
   

        let updateSequence = {
            nextnumber: newreq
               }
    
         const queryParms = '?auth=' + token  
       
             
          axios.put('https://checkrequest-20a0f.firebaseio.com/sequence.json' + queryParms,updateSequence  )
          .then( response => {                   
                       dispatch(actions.showMessageSnackbar("Request Created: " + response.data.nextnumber,actiontypes.SNACKBAR_MESSAGE_SUCCESS));  
                     //  dispatch(createNextSequence(response.data.nextnumber));            
          } )
        .catch( error => {
            //dispatch(createRequestFail(error));
        
            dispatch(actions.showMessageSnackbar("Error updating Request sequence",actiontypes.SNACKBAR_MESSAGE_ERROR));  
            
           
        } );
     
     
          
     };
 };