import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility'

const initialState = {
    newRequestValid: false,
    newRequest: null,
    requests:[],
    loadingRequest: false,
    nextsequence: 0,
}


const fetchRequestStart = (state, action) => {
    return updateObject(state, {   loadingRequest: true });
}

const fetchRequestSuccess = (state, action) => {
    return updateObject(state, {   requests: action.requests, loadingRequest: false});
}

const fetchRequestFail = (state, action) => {
    return updateObject(state, {  loadingRequest: false });
}


const newRequest = (state, action) => {
    return updateObject(state, {    newRequest: action.newRequest });
}

const newRequestVaild = (state, action) => {
    return updateObject(state, {   newRequestValid: action.newRequestValid });
}

const createRequestFail = (state, action) => {
    return updateObject(state, {  loading: false });
}


const createRequestStart = (state, action) => {
    return updateObject(state, {  loading: true });
}

const createNextSequence = (state, action) => {
    return updateObject(state, {  nextsequence: action.nextsequence });
}

const createRequestSuccess = (state, action) => {
    const newRequest = {
        ...action.requestData,
        RequestID: action.RequestID              
    };

    return updateObject(state, {  loading: false, selectedVendor: 0, request: newRequest });
}

//===


const reducer = (state = initialState, action) => {

    switch (action.type) {
      
        case  actionTypes.NEW_REQUEST: return newRequest(state, action);
        case  actionTypes.NEW_REQUEST_VALID: return  newRequestVaild(state, action);
        case  actionTypes.FETCH_REQUESTS_START: return fetchRequestStart(state, action);
        case  actionTypes.FETCH_REQUESTS_SUCCESS: return fetchRequestSuccess(state, action);
        case  actionTypes.FETCH_REQUESTS_FAIL: return fetchRequestFail(state, action);
        case  actionTypes.CREATE_REQUEST_START: return  createRequestStart(state, action);
        case  actionTypes.CREATE_REQUEST_SUCCESS: return  createRequestSuccess(state, action);
        case  actionTypes.CREATE_REQUEST_FAIL: return  createRequestFail(state, action);
        case  actionTypes.CREATE_NEW_SEQ: return  createNextSequence(state, action);
        default:
            return state;
    }


};

export default reducer;